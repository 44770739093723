/**
 * Checks if the given value is a plain object
 * @param {*} value
 * @returns {boolean}
 */
const isPlainObject = (value) => Object.prototype.toString.call(value) === '[object Object]';

/**
 * Recursively merges two objects
 * @param {Object} target
 * @param {Object} source
 * @returns {Object}
 */
export const merge = (target, source) => {
  if (!isPlainObject(target) || !isPlainObject(source)) {
    return source;
  }

  const merged = { ...target };

  Object.keys(source).forEach((key) => {
    if (typeof source[key] === 'object') {
      merged[key] = merge(target[key] || {}, source[key]);
    } else {
      merged[key] = source[key];
    }
  });

  return merged;
};
