/**
 * Check if an array represents a width and height.
 */
const isDimensionsArray = (arr) => {
  if (!Array.isArray(arr)) {
    return false;
  }

  return arr.length === 2 && Number.isFinite(arr[0]) && Number.isFinite(arr[1]);
};

/**
 * Check if an array represents a fixed-size ad.
 *
 * A fixed-size is defined as [width, height] or ['fluid'].
 */
const isFixedSize = (arr) => (
  Array.isArray(arr) && (
    isDimensionsArray(arr)
    || (arr.length === 1 && arr[0] === 'fluid')
  )
);

/**
 * Check if an array represents a multi-size ad.
 *
 * A multi-size is defined as an array of [width, height] or 'fluid'.
 */
const isMultiSize = (arr) => arr.every((size) => (
  isDimensionsArray(size) || size === 'fluid'
));

/**
 * Check if a size is valid.
 *
 * A size is defined as [width, height] or ['fluid'].
 */
export const isValidSize = (arr) => {
  if (!Array.isArray(arr)) {
    return false;
  }

  return (
    (arr.length === 1 && arr[0] === 'fluid')
    || (arr.length === 2 && Number.isFinite(arr[0]) && Number.isFinite(arr[1]))
  );
};

/**
 * Check if the given sizes indicate a fixed or multi-sized slot.
 *
 * https://developers.google.com/publisher-tag/guides/ad-sizes
 */
export const isFixedOrMultiSize = (sizes) => (
  isFixedSize(sizes) || isMultiSize(sizes)
);

/**
 * Define responsive ad sizes for the given slot.
 */
export const defineResponsiveSizes = (slot, sizes) => {
  const mapping = googletag.sizeMapping();

  sizes.forEach((adSize) => mapping.addSize(adSize[0], adSize[1]));
  slot.defineSizeMapping(mapping.build());
};
