import { debugMessage } from '../../debug.js';
import { defineAdSlot, getDefinedAdSlot } from '../../define.js';
import { HEADER_BIDDER_TIMEOUT } from '../../events.js';
import { getConfigValue } from '../../get-config.js';
import { logger } from '../../logger.js';
import { resetPrebid } from '../../refresh/prebid.js';
import { allBidsBack, setBidderBack } from '../utils/bid-manager.js';
import { attachPreBidPerformanceEventListenersOnce } from './performance.js';

const HB_INVENTORY_TYPE = 'AUPDivID';
const HB_SOURCE_TYPE = 'auction';
const HB_DESTINATION_TYPE = 'callback';
const PREBID_CONFIG_KEY = 'prebid';

/**
 * Adds to pbjs.que function that invokes pbjs.ppi.requestBidsPrebid
 * with the array of transaction objects
 * @param {Array.<TransactionObject>} transactionObjects - transaction objects
 */
const request = (transactionObjects) => {
  window.pbjs = window.pbjs || {};
  window.pbjs.que = window.pbjs.que || [];
  const pbQueCalledEventData = {
    event: 'pbDD_calling_queue',
    silenceConsole: true,
    forcePush: true,
  };
  logger.info(pbQueCalledEventData);
  debugMessage('pbDD_calling_queue');

  window.pbjs.que.push(() => {
    const pbQueRunningEventData = {
      event: 'pbDD_queue_running',
      silenceConsole: true,
      forcePush: true,
    };
    logger.info(pbQueRunningEventData);
    debugMessage('pbDD_queue_running');
    if (!window.pbjs.ppi) {
      debugMessage(
        'Prebid: Not requesting bids as window.pbjs.ppi is not defined',
      );
      const pbNotDefinedEventData = {
        event: 'pbDD_Not requesting bids as window.pbjs.ppi is not defined',
        silenceConsole: true,
        forcePush: true,
      };
      logger.warn(pbNotDefinedEventData);
      return;
    }

    attachPreBidPerformanceEventListenersOnce(window.pbjs);
    debugMessage('Prebid: Requesting bids', {
      transactionObjects,
    });

    window.pbjs.ppi.requestBids(transactionObjects);

    const pbRequestBidsEventData = {
      event: 'pbDD_PPI_requesting_bids',
      silenceConsole: true,
      forcePush: true,
    };
    logger.info(pbRequestBidsEventData);
    debugMessage('pbDD_PPI_requesting_bids');

    transactionObjects.forEach((transaction) => {
      const pbIndividualBidrequests = {
        event: 'pbDD_individual_bid_request',
        source: transaction.hbSource?.type,
        inventoryType: transaction.hbInventory?.type,
        adUnitCode: transaction.hbInventory.values?.adUnitCode,
        sizes: transaction.hbInventory?.sizes,
        silenceConsole: true,
        forcePush: true,
      };
      debugMessage(pbIndividualBidrequests);
      logger.info(pbIndividualBidrequests);
    });
  });
};


/**
 * Strips the div id from the match object similarly
 * to how 'gpt' destination module does
 * @param {MatchObject} matchObj - match object
 * @returns {string} id of the div associated with ad slot
 */
const getDivId = (matchObj) => matchObj.transactionObject?.hbDestination?.values?.div
  || matchObj.transactionObject?.divId
  || matchObj.transactionObject?.hbInventory?.values?.name;

/**
 * Returns a callback for given slot configuration that
 * looks for existing GPT slot or defines a new one,
 * then sets prebid targeting and refreshes the ad slot.
 * @param {SlotConfig} slot - slot configuration
 * @param {CallbackCounter} callbackCounter - object with functions to count invoked callbacks
 * @returns {PpiCallback} callback to be invoked when the auction is finished
 */
const getCallback = (slot, callbackCounter) => (matchObj, timeout = 0) => {
  const divId = getDivId(matchObj);

  resetPrebid(divId);

  if (window.pbjs[`adserverCalled-${divId}`]) return;

  window.pbjs[`adserverCalled-${divId}`] = true;
  debugMessage('Prebid: Got results', { matchObj });

  googletag.cmd.push(() => {
    let gptAdSlot = getDefinedAdSlot(divId);

    if (!gptAdSlot) {
      // Fallback if `requestAllBids` hasn't defined any slots yet for some reason
      try {
        gptAdSlot = defineAdSlot(slot);
        googletag.display(gptAdSlot);
        debugMessage('preBid: getCallback-> adslot not defined, defining here');
      } catch (error) {
        logger.error(
          `adslots | unable to define and register initially undefined ad slots,
          {error: ${error.message}}`,
        );
        return;
      }
    }

    callbackCounter.callbackInvoked(gptAdSlot);

    // Case when adUnit is not defined, e.g. when sizes
    // does not match any of the sizes in the ad unit pattern
    if (matchObj?.adUnit?.code) {
      pbjs.setTargetingForGPTAsync([matchObj.adUnit.code]);
    }

    if (callbackCounter.isLastCallback()) {
      setBidderBack('prebid', true);
      // setGlobal('prebid', true);
      allBidsBack(callbackCounter.getGptSlots());
    }

    if (timeout && callbackCounter.isLastCallback()) {
      const pbHeaderBidderTimeoutEvent = {
        event: `pbDD_header_bidder_timeout_called_after_${timeout}ms`,
        silenceConsole: true,
        forcePush: true,
      };
      logger.warn(`prebid | bidder timeout, {error: ${pbHeaderBidderTimeoutEvent}}`);
      debugMessage(`pbDD_header_bidder_timeout_called_after_${timeout}ms`);
      debugMessage(`Header bidder timeout called after ${timeout}ms`);
      window.dispatchEvent(new Event(HEADER_BIDDER_TIMEOUT));
    }
  });
};

/**
 * Returns object with methods to count invoked callbacks
 * @param {number} adSlotsNumber - Number of ad slots in prebid request
 * @returns {CallbackCounter} object with functions to count invoked callbacks
 */
export const getCallbackCounter = (adSlotsNumber) => {
  const gptSlots = [];

  const callbackInvoked = (gptSlot) => {
    gptSlots.push(gptSlot);
  };

  const isLastCallback = () => gptSlots.length === adSlotsNumber;

  const getGptSlots = () => gptSlots;

  return {
    callbackInvoked,
    isLastCallback,
    getGptSlots,
  };
};

/**
 * Obtains header bidder timeout value and invokes callbacks
 * for each transaction object if ad server was not called
 * after certain amount of time
 * @param {Array.<TransactionObject>} transactionObjects - transaction objects
 */
const setHeaderBidderTimeout = (transactionObjects) => {
  const prebidConfig = getConfigValue(PREBID_CONFIG_KEY);
  const defaultHeaderBidderTimeout = 1000;
  let timeout = 0;

  if (prebidConfig?.active) {
    timeout = Number(prebidConfig?.timeout || defaultHeaderBidderTimeout);
  }

  if (Number.isNaN(timeout) || timeout < 0) {
    logger.error(new Error(
      'The "headerBidderTimeout" option is not a positive number: '
      + `received "${prebidConfig?.timeout}"`,
    ));
  }

  setTimeout(() => {
    transactionObjects.forEach((transactionObject) => {
      transactionObject.hbDestination.values.callback(
        { transactionObject },
        timeout,
      );
    });
  }, timeout);
};

/**
 * Creates PPI transaction objects from slots config
 * and passes them to pbjs.ppi.requestBidsPrebid
 * @param {(SlotConfig | Array.<SlotConfig>)} slots - slots configuration
 */
export const requestBidsPrebid = (slots) => {
  const slotsConfigArray = Array.isArray(slots) ? slots : [slots];
  const callbackCounter = getCallbackCounter(slotsConfigArray.length);
  const prebidConfig = getConfigValue(PREBID_CONFIG_KEY);
  const transactionObjects = slotsConfigArray.map((slot) => ({
    hbInventory: {
      type: HB_INVENTORY_TYPE,
      values: {
        name: slot.id,
        adUnitCode: slot.id,
      },
    },
    hbSource: { type: HB_SOURCE_TYPE },
    hbDestination: {
      type: HB_DESTINATION_TYPE,
      values: {
        callback: getCallback(slot, callbackCounter),
      },
    },
  }));

  if (prebidConfig?.active) {
    request(transactionObjects);
  }

  setHeaderBidderTimeout(transactionObjects);
};
