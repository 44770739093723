import { getTargetPercentage } from './config.js';
import { getSlotState } from './state.js';

/**
 * Get the duration that an ad slot was in view.
 *
 * If the slot was in view the last time the state was updated then append the
 * duration between then and now to any previously tracked duration.
 */
const getInViewDuration = (slot, sinceDate) => {
  const state = getSlotState(slot);
  let inViewDuration = state.inViewDuration || 0;

  if (state.inView) {
    inViewDuration += sinceDate - state.inViewUpdated;
  }

  return inViewDuration;
};

/**
 * Get various details about the viewability of an ad slot.
 *
 * Expects a `SlotVisibilityChangedEvent`.
 * @see https://developers.google.com/publisher-tag/reference#googletag.events.slotvisibilitychangedevent
 */
export const getInViewDetails = (gptEvent) => {
  const { slot, inViewPercentage } = gptEvent;
  const now = new Date();

  return {
    inView: inViewPercentage >= getTargetPercentage(),
    inViewPercentage,
    inViewUpdated: now,
    inViewDuration: getInViewDuration(slot, now),
  };
};

/**
 * Check if a slot was viewable.
 *
 * By default, a slot is considered viewable if it has been in view for at
 * least one second, plus the duration of the ad load buffer. That base
 * duration is configurable. Generally, manual refreshes will fire after the
 * default one second and scheduled refreshes will fire after the time set in
 * the admin (e.g. twenty seconds).
 */
export const wasSlotViewable = (slot, n) => {
  const now = new Date();
  const inViewDuration = getInViewDuration(slot, now);

  if (!inViewDuration) {
    return false;
  }

  return inViewDuration >= n;
};
