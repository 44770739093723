/* eslint no-param-reassign: ["error", { "props": false }] */

/**
 * Deletes property from object by path.
 * @param {Object} target
 * @returns {Function}
 */
export const clear = (target) => (/** @type {string} */ path) => {
  if (!target) {
    return;
  }

  const [key, ...rest] = path.split('.');

  if (rest.length === 0) {
    delete target[key];

    return;
  }

  clear(target[key])(rest.join('.'));
};
