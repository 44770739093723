import adConfig from '../brand-new-ad-config.json';
import { logger } from './logger.js';

const DEFAULT_CONFIG_KEY = 'default';

/**
 * Returns config value associated with the key.
 * @param {string} key - the key of the config value bo be returned
 * @returns config value under the key if present, undefined otherwise
 */
export const getConfigValue = (key) => adConfig[key];

/**
 * Returns config value associated with the key for specified content type
 * or default config.
 * @param {string} key - the key of the config value bo be returned
 * @param {string} contentType - content type to find config for
 * @returns config value for the content type under the key if present, undefined otherwise
 */
export const getConfigValueForContentType = (key, contentType) => {
  const configValues = adConfig[key];
  const contentTypeConfig = configValues.find((config) => config[0].includes(contentType))?.[1];

  if (contentTypeConfig) {
    return contentTypeConfig;
  }

  return configValues.find((config) => config[0].includes(DEFAULT_CONFIG_KEY))?.[1];
};

/**
 * Returns ad slot config for the specified content type, key and index
 * or a default config.
 * @param {string} contentType - content type to find ad slot config for
 * @param {string} key - ad slot key
 * @param {string} index - ad slot index
 * @returns ad slot config for the specified content type, key and index,
 * default config or undefined if no config applicable
 */
export const getAdSlotConfig = (contentType, key, index) => {
  const { adSlots: adSlotsConfig } = adConfig;

  if (!adSlotsConfig) {
    logger.error(
      `Cannot find config for ${contentType}--${key}--${index},
      ad config file does not contain ad slots config`,
    );
    return undefined;
  }

  return (
    adSlotsConfig[`${contentType}--${key}--${index}`]
    || adSlotsConfig[`${contentType}--${key}--default`]
    || adSlotsConfig[`default--${key}--${index}`]
    || adSlotsConfig[`default--${key}--default`]
  );
};
