/* eslint-disable no-undef */
import { getSlotElementIds } from '../element-ids.js';
import { getGlobal, setGlobal } from '../global.js';
import { isSlotExcluded } from './exclusion/index.js';
import { initRefreshState } from './state.js';
import { wasSlotViewable } from './viewability.js';
import { debugMessage } from '../debug.js';
import { getViewableTargetTime } from './config.js';

import { enrichWithConfig } from '../define.js';
import { getGlobalSettings } from '../settings.js';
import { slotIdToSlotConfig } from '../slot-ids.js';
import { requestAllBids } from '../bids/request-all-bids.js';

const STATE_KEY = 'refreshQueue';

const getQueuedIds = () => [...(getGlobal(STATE_KEY) || [])];
const setQueuedIds = (slotElementIds) => { setGlobal(STATE_KEY, new Set(slotElementIds)); };

/**
 * Refresh an ad slot (if viewable).
 */
const refreshSlot = (slot) => {
  debugMessage('TAM: `refreshSlot` invoked');
  const targetTime = getViewableTargetTime();

  if (!wasSlotViewable(slot, targetTime)) {
    return false;
  }

  const slotElementId = slot.getSlotElementId();

  debugMessage('Refreshing ad slot', { slot });

  // Reset the slot state, refresh it by calling requestBidsPrebid
  // with GPT slot and slot config array
  // and remove it from the queue.
  initRefreshState(slot);
  const { key, index } = slotIdToSlotConfig(slotElementId);
  const slotConfig = enrichWithConfig(getGlobalSettings()?.targets?.subcat)({ key, index });

  // requets bidders
  requestAllBids(slotConfig);
  setQueuedIds(getQueuedIds().filter((id) => id !== slotElementId));

  return true;
};
/**
 * Process the queue.
 *
 * Refresh any slots that are considered refreshable, leaving behind any that
 * aren't.
 */
const processQueue = () => {
  const queuedIds = getQueuedIds();
  const queuedSlots = googletag.pubads().getSlots().filter((slot) => (
    queuedIds.includes(slot.getSlotElementId())
  ));

  const unrefreshedSlots = queuedSlots.filter((slot) => !refreshSlot(slot));

  setQueuedIds(getSlotElementIds(unrefreshedSlots));
};

/**
 * Queue up slots for refresh.
 */
export const queueForRefresh = (slots) => {
  if (!slots) {
    return;
  }

  const slotsArr = Array.isArray(slots) ? slots : [slots];
  const queuedIds = getQueuedIds();

  // Filter to get slots that are not excluded from refreshes and are not
  // already queued.
  const slotsToQueue = slotsArr
    .filter((slot) => !isSlotExcluded(slot))
    .filter((slot) => !queuedIds.includes(slot.getSlotElementId()));

  if (!slotsToQueue.length) {
    return;
  }

  debugMessage('Queuing slot(s) for refresh', { slots });

  // Add any unrefreshed slots to the queue.
  setQueuedIds([
    ...queuedIds,
    ...getSlotElementIds(slotsToQueue),
  ]);

  // Process any slots that are refreshable right away.
  processQueue();
};

/**
 * Setup a timer to processes the refresh queue.
 */
export const watchRefreshQueue = () => {
  setInterval(processQueue, 200);
};
