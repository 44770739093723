import { getSlotState, updateSlotState } from '../refresh/state.js';
import { getInViewDetails } from '../refresh/viewability.js';

/**
 * Handle the `SlotRenderEndedEvent`.
 * @see https://developers.google.com/publisher-tag/reference#googletag.events.slotrenderendedevent
 */
export const slotRenderEnded = (event) => {
  const { slot, campaignId } = event;

  updateSlotState(slot, {
    loaded: true,
    campaignId,
  });
};

/**
 * Handle the `SlotVisibilityChangedEvent`.
 *
 * We only start tracking the viewability once the slot has rendered.
 * @see https://developers.google.com/publisher-tag/reference#googletag.events.slotvisibilitychangedevent
 */
export const slotVisibilityChanged = (event) => {
  if (getSlotState(event.slot).loaded) {
    updateSlotState(event.slot, getInViewDetails(event));
  }
};
