export const THROTTLE_DELAY = 200;
export const LIMIT_REACHED = 'Limit reached';
export const MPU2_LVL1_UPPER_BOUND = 3;
export const MPU2_LVL2_LOWER_BOUND = 4;
export const MPU2_LVL2_UPPER_BOUND = 6;
export const MPU2_LVL3_LOWER_BOUND = 7;
export const RECIPE_MPU_LIMIT = 2;
export const LONG_FORM_MPU2_TO_MPU5_LIMIT = 4;

export const BUFFER_AMOUNT = 5;

export const AD_SLOT_MARKER = 'ad-slot-marker';
export const LIMIT_MARKER = 'limit-marker';
export const STITCH_GLOBAL_SETTINGS = 'stitch-global-settings';
export const HIGHLIGHT_BOX_CONTENT = 'highlight-box__content';
export const POST_CONTENT = 'post__content';
export const EDITOR_CONTENT = 'editor-content';
export const RECIPE_STEPS = 'recipe__method-steps';
export const STEP_HEADING = 'heading-6';
export const LONG_FORM_PARAGRAPHS = `.${POST_CONTENT} > .${EDITOR_CONTENT} > p`;
export const RECIPE_INGREDIENTS = 'recipe__ingredients';
export const HIGHLIGHT_BOX = 'highlight-box';
export const BRIGHTCOVE = 'brightcove';
export const BLOCKING_ELEMENT = 'blocking-element';
export const MEDIA_PLAYER = 'media-player';
export const OEMBED_IFRAME = 'oembed--iframe';
export const AD_PLACEMENT = 'ad-placement';
export const AD_SLOT = 'ad-slot';

export const BLOCKING_ELEMENTS = [`.${AD_SLOT}`];

export const SEMI_BLOCKING_ELEMENTS = [
  'ul',
  'ol',
  `.${MEDIA_PLAYER}`,
  `.${HIGHLIGHT_BOX}`,
  `.${BRIGHTCOVE}`,
  `.${OEMBED_IFRAME}`,
];
