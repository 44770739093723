import { logger } from './logger.js';

/**
 * Get the element ID for a single slot.
 *
 * Accounts for any undefined slots, which can happen when a dynamic ad
 * slot element is removed from the DOM.
 */
export const getSlotElementId = (slot) => {
  try {
    return slot?.getSlotElementId?.() || slot?.id;
  } catch (error) {
    logger.error(`Slot has been removed from the DOM, 
      {error: ${error.message}},
      {key}: permutive | identityGUIDs
    }`);
  }

  return null;
};

/**
 * Get the element IDs for the given slots.
 *
 * Filters out any undefined slots.
 */
export const getSlotElementIds = (slots) => {
  const slotsArr = Array.isArray(slots) ? slots : [slots];

  return slotsArr
    .map(getSlotElementId)
    .filter((slot) => slot);
};
