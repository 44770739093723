import { getSlotElementIds, getSlotElementId } from './element-ids.js';
import { getConfigValue } from './get-config.js';
import { logger } from './logger.js';

/**
 * Get a snapshot of the global state.
 */
const getGlobalState = () => {
  try {
    return JSON.parse(JSON.stringify(window.IM?.advertising || {}));
  } catch (error) {
    logger.warn(`Unable to get Global state, 
    {error: ${error.message}}, 
    {ad-service: stitcher | debug}`);
    return null;
  }
};

/**
 * Log a debug message with additional data.
 *
 * Takes a snapshot of the
 */
export const debugMessage = (message, {
  slot,
  slots,
  ...restProps
} = {}) => {
  const debugData = {
    globalState: getGlobalState(),
    date: new Date().toLocaleString(),
    ...restProps,
  };

  if (slots) {
    debugData.slotIds = getSlotElementIds(slots);
  }

  if (slot) {
    debugData.slotId = getSlotElementId(slot);
  }

  logger.debug(message, debugData);
};

/**
 * Check if debug param is present in the URL and is set to true
 */
export const isAdSlotsDebugModeOn = (location) => /[?&]stitch_debug=true/.test(location);

/**
 * Checks if Amazon TAM is enabled in config
 * @returns {boolean} - Returns true if Amazon TAM is enabled
 */
export const isTamEnabled = () => {
  const prebidConfig = getConfigValue('prebid');

  return !!prebidConfig?.amazonTam;
};
