import { getGlobalSettings } from '../settings.js';

/**
 * Get the target percentage for manual and scheduled refreshes.
 *
 * This is the percentage of an ad slot element that needs to be in view for
 * that slot to be considered viewable.
 */
export const getTargetPercentage = () => (
  getGlobalSettings().adRefresh?.percentageInView || 50
);

/**
 * Get the target duration for scheduled refreshes.
 *
 * This is the time that an ad slot needs to be in view before we will trigger
 * a scheduled refresh. Converted to milliseconds.
 *
 * An additional hardcoded buffer of three seconds is added to the configured
 * value.
 */
export const getScheduledRefreshTargetTime = () => {
  const seconds = getGlobalSettings().adRefresh?.durationInView || 20;
  const buffer = 3000;

  return (seconds * 1000) + buffer;
};

/**
 * Get the target duration for any ad slot to be considered viewable.
 *
 * The `minViewableSeconds` comes from GPTs active view criteria. An additional
 * hardcoded `buffer` of one second is added to this value to give the slot a
 * chance to load.
 */
export const getViewableTargetTime = () => {
  const minViewableSeconds = 1000;
  const buffer = 1500;

  return minViewableSeconds + buffer;
};

/**
 * Get any excluded campaign IDs.
 *
 * This is currently stored as a comma-separated string in the settings passed
 * to this package. GPT campaign IDs are numbers. So convert to an array of
 * numbers.
 * @see https://developers.google.com/publisher-tag/reference#googletag.ResponseInformation_campaignId
 */
export const getExcludedCampaigns = () => {
  const { excludedOrderIds } = getGlobalSettings().adRefresh || {};

  if (!excludedOrderIds) {
    return [];
  }

  if (Array.isArray(excludedOrderIds)) {
    return excludedOrderIds;
  }

  return excludedOrderIds
    .split(',')
    .map((str) => {
      const campaignId = Number(str.trim());

      if (!Number.isFinite(campaignId)) {
        return null;
      }

      return campaignId;
    })
    .filter((x) => x);
};
