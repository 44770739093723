
import { requestAllBids } from '../bids/request-all-bids.js';
import { getConfigValueForContentType } from '../get-config.js';
import { logger } from '../logger.js';
import '../typedef.js';

/**
 * @param {SlotConfig} slotConfig - Ad slot configuration
 * @returns {Function} handleIntersect handler function
 */
const getHandleIntersect = (slotConfig) => {
  const handleIntersect = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        requestAllBids(slotConfig);
        observer.unobserve(entry.target);
      }
    });
  };

  return handleIntersect;
};

/**
 *
 * @param {number} threshold - Ratio of the ad slot visibility margin
 * @param {SlotConfig} slotConfig - Ad slot configuration
 * @description
 * <br> 1. Creates a new Intersection Observer with options based on the provided threshold
 * <br> 2. Assisgns a HTML ad slot element to the Observer
 */
export const createObserver = (threshold, slotConfig) => {
  const rootMarginBottom = window.innerHeight * threshold;
  const rootMargin = `0px 0px ${rootMarginBottom}px 0px`;
  const options = {
    rootMargin,
  };
  const slotElement = document.getElementById(slotConfig.id);
  const observer = new IntersectionObserver(
    getHandleIntersect(slotConfig),
    options,
  );

  if (!slotElement) {
    logger.info(`No ad placeholder found for ${slotConfig.id} slot, the ad will not be displayed`);

    return;
  }

  observer.observe(slotElement);
};

/**
 * @param {string} pageCat - Page category describing the type of a page (recipes/non recipes)
 * @returns {string} intersection threshold value
 */
export const getThreshold = (pageCat) => {
  const thresholdInPercent = getConfigValueForContentType('dai', pageCat)
    ?.lazyFetching?.viewport;

  if (!thresholdInPercent || Number.isNaN(thresholdInPercent)) {
    return undefined;
  }

  return thresholdInPercent / 100;
};

/**
 * @param {string} pageCat - Page category describing the type of a page (recipes/non recipes)
 * @param {Array.<SlotConfig>} slotsConfig - Ad slots configuration
 */
export const adSlotsLazyLoad = (pageCat, slotsConfig) => {
  const threshold = getThreshold(pageCat);

  slotsConfig.forEach((slot) => {
    createObserver(threshold, slot);
  });
};
