import { mark, measure } from '@immediate_media/script-utils';
import { getSizesForCurrentViewport } from '../utils/sizes.js';
import { isTamEnabled, debugMessage } from '../../debug.js';
import { setBidderBack } from '../utils/bid-manager.js';
import { getConfigValue } from '../../get-config.js';

/**
 * Function that handles the logic when Amazon TAM is enabled
 * @todo this will be extended to support Amazon TAM integration
 */
export const requestBidsTAM = (slots) => {
  if (!isTamEnabled() || !slots) return;
  debugMessage('TAM: requestBidsTAM initiated');
  mark('tam-initiated-request-bids');
  const slotsConfigArray = Array.isArray(slots) ? slots : [slots];
  window.apstag.fetchBids({
    timeout: getConfigValue('prebid')?.timeout || 1000,
    slots: slotsConfigArray.map((slot) => ({
      slotID: slot.id,
      slotName: slot.unitName,
      sizes: getSizesForCurrentViewport(slot.sizes),
    })),
  }, (bids) => {
    googletag.cmd.push(() => {
      window.apstag.setDisplayBids();
      setBidderBack('aps', true);
      mark('tam-display-bids');
      measure('tam-fetchBids-toDisplayBids', 'tam-initiated-request-bids', 'tam-display-bids');
      debugMessage('TAM: Bids are back, setting the global state of aps to true', { bids });
    });
  });
};
