import { mark, measure } from '@immediate_media/script-utils';
import { addAdCount } from '../lux.js';

export const isTimeToCmpMarkerSet = () => performance?.getEntriesByName('time-to-cmp')?.length === 1;

/**
 * Add performance markers.
 */
export const trackPerformance = () => {
  let firstAdId = null;
  let renderedAds = 0;
  const currentMarkers = [];
  const slotEventsCount = {};

  const markOnce = (name) => {
    if (!currentMarkers.includes(name)) {
      currentMarkers.push(name);

      mark(name);
    }
  };

  const measureOnce = (name, startMark, endMark) => {
    if (!currentMarkers.includes(name)) {
      currentMarkers.push(name);

      measure(name, startMark, endMark);
    }
  };

  const trackSlotEvent = (slotId) => {
    slotEventsCount[slotId] = slotEventsCount[slotId]
      ? slotEventsCount[slotId] + 1
      : 1;
  };

  const isLastSlotToRender = (slotId, slotsToRender) => renderedAds === slotsToRender
    && slotEventsCount[slotId] === 2;


  /**
   * Handle the `slotRequestedEvent`.
   *
   * @see https://https://developers.google.com/publisher-tag/reference#googletag.events.slotrequestedevent
   */
  const slotRequested = (event) => {
    trackSlotEvent(event.slot.getSlotElementId());

    if (!firstAdId) {
      firstAdId = event.slot.getSlotElementId();

      markOnce('time-to-first-ad-request');
      measure(
        'ad-manager-load-to-first-ad-request',
        'time-to-ad-manager-loaded',
        'time-to-first-ad-request',
      );

      if (isTimeToCmpMarkerSet()) {
        measure(
          'cmp-to-first-ad-request',
          'time-to-cmp',
          'time-to-first-ad-request',
        );
      }
    }
  };

  /**
   * Handle the `slotResponseReceivedEvent`.
   *
   * @see https://developers.google.com/publisher-tag/reference#googletag.events.slotresponsereceived
   */
  const slotResponseReceived = (event) => {
    const slotId = event.slot.getSlotElementId();

    if (event.slot.getResponseInformation() !== null) {
      trackSlotEvent(slotId);
    } else {
      delete slotEventsCount[slotId];
    }
    if (firstAdId === slotId) {
      // Time between "SlotRequestedEvent" and "SlotResponseReceived"
      markOnce('ad-response-time-end');
      measure('ad-response-time', 'time-to-first-ad-request');
    }
  };

  /**
   * Handle the `slotOnloadEvent`.
   *
   * @see https://developers.google.com/publisher-tag/reference#googletag.events.slotonloadevent
   */
  const slotOnload = (event) => {
    const slotId = event.slot.getSlotElementId();
    const slotsToRender = Object.keys(slotEventsCount).length;

    markOnce('time-to-first-ad-display');

    if (isTimeToCmpMarkerSet()) {
      measureOnce(
        'cmp-to-first-ad-display',
        'time-to-cmp',
        'time-to-first-ad-display',
      );
    }

    if (renderedAds < slotsToRender) {
      markOnce(`time-to-render-${slotId}`);
      renderedAds += 1;
    }

    if (isLastSlotToRender(slotId, slotsToRender)) {
      markOnce('time-to-last-ad-render');
      measure('ads-rendering-time', 'ad-response-time-end');
      trackSlotEvent(slotId);
      addAdCount(renderedAds);
    }

    if (firstAdId === slotId) {
      // Time between "SlotResponseReceived" and "SlotOnloadEvent"
      markOnce('ad-render-time-end');
      measure('ad-render-time', 'ad-response-time-end');

      // Time between "SlotRequestedEvent" and "SlotOnloadEvent"
      measure('ad-display-time', 'time-to-first-ad-request');
    }
  };

  /**
   * Handle the `impressionViewableEvent`.
   *
   * @see https://developers.google.com/publisher-tag/reference#googletag.events.impressionviewableevent
   */
  const impressionViewable = () => {
    markOnce('time-to-first-ad-impression');
  };

  googletag.pubads().addEventListener('slotRequested', slotRequested);
  googletag.pubads().addEventListener('slotResponseReceived', slotResponseReceived);
  googletag.pubads().addEventListener('slotOnload', slotOnload);
  googletag.pubads().addEventListener('impressionViewable', impressionViewable);
};
