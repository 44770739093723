/**
 * Function to strip out the prefix from a GUID, for when we transport it
 * to an API.
 * @param {string} guid - the guid you want to alter
 * @param {*} prefix - the prefix you want to remove from the guid
 * @returns {string} - the guid without the prefix
 */
export const removePrefix = (guid, prefix) => {
  if (!guid) return null;
  return guid.replace(prefix, '');
};
