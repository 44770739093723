import { mark } from '@immediate_media/script-utils';
import { AD_MANAGER_READY } from '../events.js';
import { process, queue } from './queue.js';

let isReady = false;

/**
 * Signals that all the callbacks have been enqueued and
 * the queue is ready to be processed.
 * Causes the queue to be processed and any callbacks
 * enqueued after this point are executed immediately.
 *
 * Successive calls to api.ready() are noop.
 * @typedef {Function} Ready
 * @returns {void}
 */

/**
 * @param {import('./api').API} api
 * @returns {Ready}
 */
export const ready = (api) => () => {
  if (isReady) {
    return;
  }
  isReady = true;

  // Process the ad queue and replace array with own implementation.
  process(api, window.adq);
  window.adq = queue(api);

  mark('time-to-ad-queue-processed');

  // Emit event to signal that the queue has been processed.
  window.dispatchEvent(new CustomEvent(AD_MANAGER_READY));
};

/**
 * Adds event listener to be called after the queue has been processed.
 * @param {Function} fn
 */
export const onReady = (fn) => {
  window.addEventListener(AD_MANAGER_READY, fn, { once: true });
};
