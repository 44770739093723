import { logger } from '../../logger.js';
import { removePrefix } from '../remove-prefix.js';
/**
 * Sends the users GUID to Permutive
 * It should be noted that we ONLY send this without a call to check if the user is logged in,
 * is because at this point, we have already retrieved the users GUID, and therefor know that
 * they are.
 * @param {string} userID - User ID you want to send to Permutive
 * @see {@link https://support.permutive.com/hc/en-us/articles/360010090580-Syncing-User-Identities|Permutive Documentation}
 */

export const sendPermutiveID = (userID) => {
  // eslint-disable-next-line camelcase
  const guid = removePrefix(userID?.permutive_identityframework_id, 'pif_');

  /**
   * If no GUID then we can't pass anything to Permutive
   */
  if (!guid) {
    logger.warn('sendPermutiveID did not run, no GUID provided');
    return null;
  }

  /**
  * If we have already ran `window.permutive.identify` then we do not need
  * to execute it again, as Permutive puts it in `permutive-data-misc` in localStorage
  *
  * @todo should this @immediate_media/permutive package?
  */
  if (typeof window !== 'undefined') {
    const localStoragePermutiveGuid = JSON.parse(window.localStorage.getItem('permutive-data-misc'));
    if (localStoragePermutiveGuid?.identities['p-guid']) {
      logger.info('sendPermutiveID did not run, p-guid is already in localStorage');
      return null;
    }
  }

  return window.permutive.identify([
    {
      id: guid,
      tag: 'p-guid',
      priority: 0,
    },
  ]);
};
