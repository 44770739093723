import { getSizesForCurrentViewport } from './bids/utils/sizes.js';

const areSizesEmpty = (sizes) => !sizes?.length;

/**
 * Checks whether slot with given config should be displayed (based on breakpoints
 * for sizes)
 * on current device type.
 * @param {SlotConfig} slotConfig - additional slots properties (sizes, targets etc.)
 * @returns {Boolean} true when given slot is allowed on current device type,
 * false otherwise
 */
export const currentDeviceTypeSlot = (slotConfig) => {
  const sizes = getSizesForCurrentViewport(slotConfig.sizes);
  const sizesEmpty = areSizesEmpty(sizes);

  return !sizesEmpty;
};
