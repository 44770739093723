import { getGlobalSettings } from '../settings.js';

/**
 * Check if ad refresh is enabled.
 */
export const isAdRefreshEnabled = () => (
  getGlobalSettings()?.adRefresh?.enabled
  || window.location.pathname.includes('radio-listings') // temp
  || window.location.pathname.includes('tv-listings')
);
