import { getContext } from './context.js';
import { logger } from '../logger.js';
/**
 * Function to retrieve a specific key:value from api context, when a callback key
 * has been provided.
 * @param {string} key - key to listen to existing
 * @returns {Promise} - object that you are searching for within the api.getContext
*/
export const retrieveContextKey = (key) => {
  if (!key) {
    return Promise.reject(new Error('retrieveContextKey failed - no key provided'));
  }
  return new Promise((resolve, reject) => {
    const callback = () => {
      try {
        resolve(getContext()[key]);
      } catch (error) {
        logger.warn(
          `retrieveContextKey was not retrieved,
          error: ${error.message},
          ad-service: permutive | identityGUIDs`,
        );
        reject(error);
      } finally {
        // always remove the event listener to reduce memory leak
        window.removeEventListener(key, callback);
      }
    };

    window.addEventListener(key, callback);
  });
};
