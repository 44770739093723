import { checkPurposes, waitForConsentChange } from '@immediate_media/consent-checker';
import { mark, measure } from '@immediate_media/script-utils';
import {
  isTimeToCmpMarkerSet,
  trackPerformance,
} from './lib/gpt/performance.js';

import {
  setupAdRefresh,
  setupScheduledAdRefresh,
} from './lib/refresh/setup.js';

import { api } from './lib/api/api.js';
import { ready } from './lib/api/ready.js';
import { initialiseTAM } from './lib/bids/index.js';
import { debugMessage, isAdSlotsDebugModeOn } from './lib/debug.js';
import { getAdSlotsOnPage, onInteractive } from './lib/dom.js';
import { createPlaceholdersMatchSettings } from './lib/dynamic-ad-injection/read-config.js';
import { setGlobal } from './lib/global.js';
import { enableGptServices, setupGptLazyLoading } from './lib/gpt/utils.js';
import { logger } from './lib/logger.js';
import { addSpeedCurveLuxData } from './lib/lux.js';
import { setMantisData } from './lib/mantis.js';
import { awaitGlobalSettings } from './lib/settings.js';
import {
  getLongFormDebugOverlay,
  getRecipeDebugOverlay,
} from './lib/stitch-debug/index.js';
import { setupPermutiveTargeting } from './lib/targeting/permutive.js';
import { watchForDynamicAdSlots } from './lib/watch.js';


import { getSlots, setBidsRequested } from './lib/api/slot.js';
import { requestAllBids } from './lib/bids/request-all-bids.js';
import { bidManager } from './lib/bids/utils/bid-manager.js';
import { currentDeviceTypeSlot } from './lib/current-device-type-slot.js';
import { enrichWithConfig } from './lib/define.js';
import { identityGUIDS } from './lib/identity-guids/index.js';
import { setupPageLevelTargeting } from './lib/targeting/page-level.js';

const REQUIRED_GLOBAL_SETTINGS = ['targets.subcat'];

/**
 * A script to run our ad management code.
 *
 * This is bundled for use as a separate async script for improved ad speed.
 *
 * Please keep this script clean, avoid any external dependencies, and make
 * sure any new functionality is fully tested. If you're thinking of adding
 * something here consider if it really needs to live here, or is there a more
 * suitable home for it. For example, we had a bunch of prop mapping stuff for
 * our React components that may as well live with the React components, being
 * the only place they're actually used, rather than complicate this script. We
 * want to keep it minimal and fast, as far as possible! 💰💰💰
 *
 * See the README of the @immediate_media/advertising package for usage details.
 */
window.googletag = window.googletag || { cmd: [] };

window.pbjs = window.pbjs || { que: [] };

mark('time-to-ad-manager-loaded');

// Initialise Amazon TAM Bidding
initialiseTAM();


debugMessage('Ad manager loaded');
const setupAdsDisplay = (globalSettings) => {
  googletag.cmd.push(() => {
    setMantisData(globalSettings?.mantis);
    setupGptLazyLoading();
    setupPageLevelTargeting(globalSettings);

    // setup Bid Manager
    bidManager();
    // setupLazyLoading includes a call to collapseEmptyDivs, which must
    // come before enableServices
    enableGptServices();

    // keep above requests
    trackPerformance();
    addSpeedCurveLuxData(globalSettings);

    setupAdRefresh();
    setupScheduledAdRefresh();

    const type = globalSettings.targets?.channel;

    watchForDynamicAdSlots();

    // check for the debug param present in the url
    if (isAdSlotsDebugModeOn(window.location.search)) {
      // Later add the logic related to debug mode
      debugMessage('Debug mode enabled');

      if (!type) {
        logger.error('Stitch debug could not be loaded');
      }

      // The default settings are switched to use ad-config-example.json file
      if (type === 'recipes') {
        getRecipeDebugOverlay({
          charLimits: createPlaceholdersMatchSettings(globalSettings),
        });
      } else if (type && type !== 'recipes') {
        getLongFormDebugOverlay({
          charLimits: createPlaceholdersMatchSettings(globalSettings),
          adsNumber: createPlaceholdersMatchSettings(globalSettings).maxAds,
        });
      }
    }
  });
};

const hideSlotsInappropriateForDevice = (slotConfigs) => {
  slotConfigs
    .filter((slot) => slot?.sizes?.length) // get non-null slots with sizes
    .forEach((slot) => {
      if (currentDeviceTypeSlot(slot)) return;

      const domElement = document.getElementById(slot.id);

      if (domElement) domElement.style.display = 'none';
    });
};

const getUnfilteredSlotConfigs = (contentType) => {
  const domSlots = getAdSlotsOnPage();
  const queueSlots = getSlots();

  return [
    ...queueSlots.map(enrichWithConfig(contentType)),
    ...domSlots,
  ];
};

/**
 * Get proper configuration for the ad slots - from ad config file
 * when slots are added via addSlot method in ad queue
 * otherwise from HTML DOM elements.
 */
const getSlotConfigs = (contentType) => getUnfilteredSlotConfigs(contentType)
  .filter((slot) => slot?.sizes?.length) // get non-null slots with sizes
  .filter(currentDeviceTypeSlot);

/**
 * Function that is specifically designed to be used within:
 * `waitForConsentChange` - we need to wait for some consent to fire:
 * - permutive
 * - preb (requestBidsPrebid)
 * - setBidsRequested
 * - identityGUIDS
 */
const consentWrapped = () => {
  // time from page load to enter consentWrapped
  mark('time-to-consentWrapped');
  // Display the ads when the DOM has fully loaded. This means that the markup
  // for all ad slots will be present and we can guarantee roadblocks.
  // See https://support.google.com/admanager/answer/183282
  onInteractive(() => {
    // time from page load to enter onInteractive event handler in consentWrapped
    mark('time-to-consentWrapped-onInteractive');
    googletag.cmd.push(() => {
      setupPermutiveTargeting();
    });
    awaitGlobalSettings(REQUIRED_GLOBAL_SETTINGS, (error, globalSettings) => {
      // time from page load to obtain global settings in consentWrapped
      mark('time-to-consentWrapped-onInteractive-awaitGlobalSettings');
      if (error) {
        logger.error(`consentWrapped: Required Global Settings could not be found, 
        {error: ${error.message}}`);
        return;
      }

      const contentType = globalSettings.targets.subcat;
      const slotConfigs = getSlotConfigs(contentType);

      // hide any slots that are not designed for the current device type
      const unfilteredSlotConfigs = getUnfilteredSlotConfigs(contentType);
      hideSlotsInappropriateForDevice(unfilteredSlotConfigs);

      // Prebid module in admanager calls GAM, even if Prebid is turned off in Global settings
      requestAllBids(slotConfigs);

      setBidsRequested();
      identityGUIDS();
    });
  });
};

/**
 * Sets Non Personlised Ads KVP for Google Publishers Tag and PreBid Non Personlised config
 * @param {Boolean} option
 */
const usePersonalisedAds = (option) => {
  try {
    const consentKey = Object.keys(localStorage).find((key) => key.startsWith('_sp_user_consent_'));

    const item = JSON.parse(localStorage.getItem(consentKey));

    if (item?.gdpr?.consentStatus?.hasConsentData === true) {
      googletag.cmd.push(() => {
        googletag.pubads().setPrivacySettings({ nonPersonalizedAds: !option });
      });
      if (window.pbjs === undefined || typeof window.pbjs.setConfig !== 'function') {
        logger.error('PreBid: pbDD_window.pbjs not correctly defined');
        return;
      }
      window.pbjs.setConfig({ deviceAccess: option });

      if (option === true) {
        performance.mark('Consent given, will show personalised Ads');
      } else {
        performance.mark('Consent is withheld, will show non-personalised Ads');
      }
      consentWrapped();
    }
  } catch (error) {
    logger.info('Consent based ads | Error in retrieving value from SourcePoint, unable to determine');
    consentWrapped();
  }
};
/**
 * Init ads.
 */
const init = () => {
  // we need to track whether this function has already been called
  // as changes to the consent string (managed by __tcfapi), will
  // cause it to be called multiple times, resulting in unexpected
  // behaviour
  const alreadyInit = window?.IM?.advertising?.init;

  if (alreadyInit) {
    debugMessage('Ad package is already initialised, exiting...');
    return;
  }

  setGlobal('init', true);

  mark('time-to-ad-manager-started');

  googletag.cmd.push(() => {
    mark('time-to-gpt-started');

    if (isTimeToCmpMarkerSet()) {
      measure('cmp-to-gpt-started', 'time-to-cmp', 'time-to-gpt-started');
    }

    // Make any calls that don't rely on markup being present as soon as possible
    googletag.pubads().disableInitialLoad();
    googletag.pubads().enableSingleRequest();
    googletag.pubads().enableAsyncRendering();

    onInteractive(() => {
      // time from page load to enter onInteractive event handler in GPT queue
      mark('time-to-gpt-onInteractive');
      awaitGlobalSettings(REQUIRED_GLOBAL_SETTINGS, (error, globalSettings) => {
        // time from page load to obtain global settings in GPT queue
        mark('time-to-gpt-onInteractive-awaitGlobalSettings');
        if (error) {
          logger.error(`onInteractive: Required Global Settings could not be found', 
          {error: ${error.message}}`);
          return;
        }

        setupAdsDisplay(globalSettings);
      });
    });
  });

  // time from page load to reach consentWrapped call (useful in combination with markers
  // in consentWrapped and waitForConsent)
  mark('time-to-init-waitForConsent');

  waitForConsentChange(() => {
    usePersonalisedAds(checkPurposes([1, 2, 3, 4, 7, 9, 10]));
  });
};

// Make sure api.ready() is called once dom is loaded and all potential
// snippets are parsed and it hasn't been called in any of them.
onInteractive(ready(api));

init();
