import { getTextElements, getLimitMarkers } from './selectors.js';
import {
  injectAdSlotMarkers,
  appendGlobalSettingsIndicator,
  injectLimitMarker,
} from './overlays.js';
import * as c from './constants.js';
import { getElementLength, breakLoop, continueLoop } from './utils.js';

import '../typedef.js';

/**
 * Get number of ingredients in recipe type page
 * @returns {number} Total number of ingredients in recipe
 */
export const getNumberOfIngredients = () => document
  .querySelector(`.${c.RECIPE_INGREDIENTS}`)
  .querySelectorAll('li')
  .length;

/**
 * Get max character count limits based on
 * the number of recipe ingredients
 * @param {number} numberOfIngredients - Total number of ingredients in recipe
 * @param {RecipeCharLimits} charLimits - Character count limits for recipe
 * @returns {InferredCharLimits} Character count limits for MPU 2 and MPU 3 inferred from recipe
 */
export const getLimits = (
  numberOfIngredients,
  charLimits,
) => {
  const {
    mpu2Lvl1,
    mpu2Lvl2,
    mpu2Lvl3,
    mpu3,
  } = charLimits;
  let mpu2CharLimit;
  let mpu3CharLimit;

  if (numberOfIngredients <= c.MPU2_LVL1_UPPER_BOUND) {
    mpu2CharLimit = mpu2Lvl1;
  }
  if (
    numberOfIngredients >= c.MPU2_LVL2_LOWER_BOUND
    && numberOfIngredients <= c.MPU2_LVL2_UPPER_BOUND
  ) {
    mpu2CharLimit = mpu2Lvl2;
  }
  if (numberOfIngredients >= c.MPU2_LVL3_LOWER_BOUND) {
    mpu2CharLimit = mpu2Lvl3;
    mpu3CharLimit = mpu2Lvl3 + mpu3;
  }

  return {
    mpu2CharLimit,
    ...(mpu3CharLimit && { mpu3CharLimit }),
  };
};

/**
 * Inject limit markers in recipe
 * @param {InferredCharLimits} inferredCharLimits
 * - Char count limits for MPU 2 and MPU 3 inffered from recipe
 * @param {Array.<Element>} textElements - Paragraph HTML elements
 */
export const injectRecipeLimitMarkers = (inferredCharLimits, textElements) => {
  let currentCharLimit = inferredCharLimits.mpu2CharLimit;
  let mpuNumber = 0;

  [...textElements].every((element) => {
    const elementLength = getElementLength(element);
    // if limit is lower than the current element length inject the limit marker
    if (currentCharLimit <= elementLength) {
      const limitMarkerIndex = currentCharLimit;
      injectLimitMarker(element, limitMarkerIndex);
      // after injecting limit marker for mpu2 limit needs to be set to mpu3CharLimit
      currentCharLimit = inferredCharLimits.mpu3CharLimit;
      mpuNumber += 1;

      if (mpuNumber === c.RECIPE_MPU_LIMIT) return breakLoop();

      // charsLeft stores a number of characters left in paragraph after the limit marker
      const charsLeft = elementLength - limitMarkerIndex;
      // if limit is less or equal to rest value it means that mpu3CharLimit was reached
      // in the same paragraph
      if (currentCharLimit <= charsLeft) {
        injectLimitMarker(element, currentCharLimit + limitMarkerIndex);

        return breakLoop();
      }
      // else - substract rest from limit and move on to the next element
      currentCharLimit -= charsLeft;
      // if the limit exceedes current element length - lower the limit by the element length
      // and move on to the next element
    } else if (currentCharLimit > elementLength) currentCharLimit -= elementLength;

    return continueLoop();
  });
};

/**
 * Creates limit tags for recipe-type content
 * @param {Object} config - Configuration for recipe overlay
 * @param {RecipeCharLimits} config.charLimits
 */
export const getRecipeDebugOverlay = (config) => {
  const numberOfIngredients = getNumberOfIngredients();
  const limits = getLimits(numberOfIngredients, config.charLimits);
  const textElements = getTextElements('recipe');

  appendGlobalSettingsIndicator([
    { label: 'MPU2 char limit', value: limits.mpu2CharLimit },
    { label: 'MPU3 char limit', value: limits.mpu3CharLimit || 'N.A.' },
  ]);
  injectRecipeLimitMarkers(limits, textElements);
  const limitMarkers = getLimitMarkers();
  injectAdSlotMarkers(textElements, [], [], limitMarkers);
};
