import { debugMessage } from '../../debug.js';

/**
 * Check if a slot contains an in-stream video ad.
 */
export const isInstreamVideo = (slot) => {
  const slotElement = document.getElementById(slot.getSlotElementId());

  if (slotElement?.querySelector('#im-instream-video-con') != null) {
    debugMessage('Ad slot contains an in-stream video ad', { slot });

    return true;
  }

  return false;
};
