import { HEADER_BIDDER_TIMEOUT } from './events.js';
import { getConfigValue } from './get-config.js';
import { logger } from './logger.js';
import { addPermutiveSegmentsToLux } from './targeting/permutive.js';

const addGlobalSettings = (globalSettings) => {
  const prebidConfig = getConfigValue('prebid');
  const lazyRenderingConfig = getConfigValue('lazyRendering');

  if (globalSettings?.adRefresh?.enabled !== undefined) {
    window.LUX.addData('ad-refresh', globalSettings?.adRefresh?.enabled);
  }

  if (globalSettings?.adRefresh?.durationInView !== undefined) {
    window.LUX.addData(
      'ad-refresh-duration-in-view',
      globalSettings?.adRefresh?.durationInView,
    );
  }

  if (prebidConfig?.timeout !== undefined) {
    window.LUX.addData('header-bidding-timeout', prebidConfig?.timeout);
  }

  if (lazyRenderingConfig?.enabled !== undefined) {
    window.LUX.addData('ads-lazy-loading', lazyRenderingConfig?.enabled);
  }
};

/**
 * Add SpeedCurve LUX data.
 */
export const addSpeedCurveLuxData = (globalSettings) => {
  if (!window.LUX) {
    logger.warn(
      `Could not add SpeedCurve LUX data as window.LUX is ${window.LUX}`,
    );

    return;
  }

  addPermutiveSegmentsToLux();
  addGlobalSettings(globalSettings);

  // Note that this might be called multiple times per page if that page has
  // dynamic ad slots where the timeout was also reached.
  window.addEventListener(HEADER_BIDDER_TIMEOUT, () => {
    window.LUX.addData('prebid-timeout', true);
  });
};

export const addAdCount = (adCount) => {
  // searching for iframe to filter out empty ad slots
  const injectedAdCount = document.querySelectorAll(`
  #ad-post-mpu-top-mobile > div > iframe,
  #ad-post-mpu-teads > div > iframe,
  #ad-post-mpu-bottom-mobile > div > iframe
  `).length;
  window.LUX.addData('injected-ad-count', injectedAdCount);
  window.LUX.addData('ad-count', adCount);
};
