/* eslint-disable camelcase */
import { debugMessage } from './debug.js';
import { getAdSlotConfig } from './get-config.js';
import { defineResponsiveSizes, isFixedOrMultiSize } from './gpt/sizes.js';
import { logger } from './logger.js';
import { getGlobalSettings } from './settings.js';
import './typedef.js';


const SLOT_ID_SEPARATOR = '--';
const OOP_SLOT_KEY = 'oop';

/**
 * Get a defined ad slot by ID, if any.
 */
export const getDefinedAdSlot = (id) => {
  const existingSlots = googletag.pubads().getSlots();

  return existingSlots.find((slot) => id === slot.getSlotElementId());
};

/**
 * Check if sizes have proper format.
 */
export const validateSizes = (sizes) => {
  if (!Array.isArray(sizes)) {
    throw new Error(
      `Attempted to register an ad slot with invalid sizes: ${sizes}`,
    );
  }
};

/**
 * Avoid trying to add a slot with the same ID twice.
 */
export const slotExists = (id) => {
  const existingSlot = getDefinedAdSlot(id);

  if (existingSlot) {
    debugMessage(`A slot with id ${id} is already defined`, {
      slot: existingSlot,
    });

    return true;
  }

  return false;
};

/**
 * Get final targets for ad slot.
 */
const getFinalTargets = (targets, isOutOfPageSlot) => {
  const finalTargets = targets || {};

  if (isOutOfPageSlot) {
    finalTargets.pos = [''];
  }

  return finalTargets;
};

/**
 * Constructs unit name based on unitBase from ad config
 * concatenated with channel and primary_cat from targeting data.
 * Uses unitName from global settings as a fallback.
 * @returns unit name
 */
const getUnitName = () => {
  const globalSettings = getGlobalSettings();

  return globalSettings.unitName;

  // FIXME: This is a temporary fix for the unit name issue for FAB 1 pages.
  // The following lines need to be uncommented once the issue is fixed.

  // const unitBase = getConfigValue('unitBase');
  // const { channel, primary_cat } = globalSettings.targets;

  // if (!unitBase || !channel || !primary_cat) {
  //   return globalSettings.unitName;
  // }

  // return `${unitBase}/${channel}/${primary_cat}`;
};

/**
 * Define ad slot within GPT and assign sizes.
 */
const defineAdSlotWithinGpt = ({ id, isOutOfPageSlot, sizes }) => {
  const nonResponsive = isFixedOrMultiSize(sizes);
  const initialSizes = nonResponsive ? sizes : [0, 0];
  const unitName = getUnitName();
  const slot = isOutOfPageSlot
    ? googletag.defineOutOfPageSlot(unitName, id)
    : googletag.defineSlot(unitName, initialSizes, id);

  if (!slot) {
    throw new Error(`Failed to define slot for element with id "${id}"`);
  }

  if (!nonResponsive) {
    defineResponsiveSizes(slot, sizes);
  }

  return slot;
};

/**
 * Define an ad slot.
 */
export const defineAdSlot = ({ id, sizes, targets }) => {
  const isOutOfPageSlot = id.includes(OOP_SLOT_KEY);
  const finalTargets = getFinalTargets(targets, isOutOfPageSlot);

  if (slotExists(id)) return null;
  validateSizes(sizes);
  debugMessage('Defining ad slot', {
    id,
    sizes,
    targets: finalTargets,
    isOutOfPageSlot,
  });

  const slot = defineAdSlotWithinGpt({
    id,
    isOutOfPageSlot,
    sizes,
  });

  slot.addService(googletag.pubads()).updateTargetingFromMap(finalTargets);

  return slot;
};

/**
 * Join arguments with SLOT_ID_SEPARATOR separator
 * @param {...any} args
 */
const joinWithSeparator = (...args) => args.filter((s) => s != null).join(SLOT_ID_SEPARATOR);

/**
 * Get slot targets based on provided targets, slot key and index.
 * @param {AdTargets} targets
 */
const getSlotTargets = (targets, pos, key, index) => ({
  ...targets,
  pos: pos
    ? [...pos.split(','), joinWithSeparator(key, index)]
    : [joinWithSeparator(key, index)],
});

/**
 * Enrich slot definitions with properties like sizes,
 * targets etc. taken from the ad config file.
 * @param {AdSlotsConfig} adSlotsConfig additional slots properties (sizes, targets etc.)
 * associated with slot keys
 * @param {AddSlotParam} config object containing slot key and index
 * @returns {SlotConfig} ad slot config
 */
export const enrichWithConfig = (contentType) => (slot) => {
  const elementId = joinWithSeparator(contentType, slot.key, slot.index);
  const adSlotConfig = getAdSlotConfig(contentType, slot.key, slot.index);

  if (!adSlotConfig) {
    logger.error(
      `No ad slot config found for key "${slot.key}" (element id "${elementId}")`,
    );

    return null;
  }

  /** If are on a search page, and the <AdSlot/> has been passed in
   *  the `searchTerm` param, then we manually add it to the adSlots targeting.
   *  We avoid the ad-config, as this is entirely specific to search results, and
   *  native (nova) advertising
   * @todo - move this to ad-config for native advertising slots
   * */
  if (slot && slot?.searchTerm) {
    adSlotConfig.keyValuePair.kw = `${slot.searchTerm}`;
    adSlotConfig.keyValuePair.st = `${slot.fullSearchTerm}`;
  }

  return {
    id: elementId,
    targets: getSlotTargets(
      adSlotConfig.keyValuePair,
      adSlotConfig.pos,
      slot.key,
      slot.index,
    ),
    sizes: adSlotConfig.sizes,
    showDisclaimer: Boolean(adSlotConfig?.showDisclaimer),
  };
};
