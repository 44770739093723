import '../typedef.js';

/**
 * Object that holds the configuration about the mpu2 level limited by ingredients count.
 * Based on the ingredients count on the recipes page mpu2 char count limit will be applied
 * to finding proper placeholders for dynamic ads to inject.
 */
const MPU2_RECIPES_BOUND = {
  lvl1: {
    lowerBound: 1,
    upperBound: 3,
  },
  lvl2: {
    lowerBound: 4,
    upperBound: 6,
  },
  lvl3: {
    lowerBound: 7,
  },
};
const PARAGRAPH_BUFFER_LIMIT = 6;

/**
 * @param {number} ingredientsCount - Number of ingredients in the recipe page category
 * @param {number} lowerBound - Min ingredients to determine the level of characters limit
 * @param {(number | null)} upperBound - Max ingredients to determine the level of characters limit
 *
 * @returns {boolean}
 * true or false, based on if the ingredientsCount is within the upper and lower bounds
 * @example getMpu2Limit(5, { mpuBounds.lvl1.lowerBounds, mpuBounds.lvl1.upperBounds })
 */
const getMpu2Limit = (ingredientsCount, { lowerBound, upperBound = null }) => {
  if (!upperBound) {
    return ingredientsCount >= lowerBound;
  }
  return ingredientsCount >= lowerBound && ingredientsCount <= upperBound;
};

/**
 * @param {RecipeCharLimits} recipeCharLimits - Characters limit for recipe page category
 * @param {number} ingredientsCount - Number of ingredients in the recipe page category
 *
 * @returns {number} current char limit based on ingredients count from recipe
 */
const getMpu2LvlLimit = (recipeCharLimits, ingredientsCount) => {
  switch (true) {
    case getMpu2Limit(ingredientsCount, MPU2_RECIPES_BOUND.lvl1):
      return recipeCharLimits.mpu2Lvl1;
    case getMpu2Limit(ingredientsCount, MPU2_RECIPES_BOUND.lvl2):
      return recipeCharLimits.mpu2Lvl2;
    case getMpu2Limit(ingredientsCount, MPU2_RECIPES_BOUND.lvl3):
      return recipeCharLimits.mpu2Lvl3;
    default:
      return 0;
  }
};

/**
 * @param {string} selector - id of the ad
 *
 * @returns {<Element>} returns empty div placeholder found by given selector
 */
export const findEmptyDivPlaceholderNoDataCount = (selector) => document.querySelector(selector);

/**
 * @param {string} selector - Selector of the empty div placeholder
 *
 * @returns {Array.<Element>} html dom div elements by given selector
 */
export const findEmptyDivPlaceholders = (selector) => [...document.querySelectorAll(selector)]
  .filter((div) => div.dataset?.count);

/**
 * @param {string} selector - Selector of the ingredients list item
 *
 * @returns {number} ingredients count by given selector
 */
export const getIngredientsCount = (selector) => document.querySelectorAll(selector).length;

/**
 * @param {number} dataSet - Value of the placeholder dataSet attribute
 * @param {number} currentCharCount - Current number of the characters
 *
 * @returns {boolean} Whether the char limit is found
 */
const isCharLimitFound = (dataSet, currentCharCount) => dataSet >= currentCharCount;

/**
 * @param {Element} placeholder - Placeholder for the ad slot
 * @param {number} currentCharCount - Current number of the characters
 *
 * @returns {boolean} Whether the placeholder can be matched
 */
const canBeMatched = (placeholder, currentCharCount) => placeholder.dataset?.paragraphsLeft
  && isCharLimitFound(Number(placeholder.dataset?.count), currentCharCount);

/**
 * @param {number} placeholdersMatched - Number of matched placeholders
 * @returns {boolean} Whether limit of matched placeholders for mpu2 is reached
 */
const ismpu3To5NotReached = (placeholdersMatched) => placeholdersMatched <= 3;

/**
 * @param {number} placeholdersMatched - Number of matched placeholders
 * @param {LongFormCharLimitsConfig} longFormConfig - Char limits config for long form
 *
 * @returns {number} current char limit for long form based on amount of placeholders found
 */
const getCurrentCharLimit = (placeholdersMatched, longFormConfig) => (ismpu3To5NotReached(
  placeholdersMatched,
)
  ? longFormConfig.mpu3To5
  : longFormConfig.mpu6Plus);

/**
 * @param {Element} placeholder - Placeholder for the ad slot
 * @returns {boolean} Whether placeholder is placed within mpu6 limit buffer
 */
const isWithinBuffer = (placeholder) => (Number(
  placeholder.dataset?.paragraphsLeft,
) <= PARAGRAPH_BUFFER_LIMIT
);

/**
 * @param {Array.<Element>} placeholders - Placeholders for the ad slot
 * @param {LongFormCharLimitsConfig} longFormConfig - Char limits config for long form
 * @returns {Array.<Element>}
 * matched placeholders by matching their data-count attribute to characters limits
 */
export const findPlaceholdersForLongForm = (placeholders, longFormConfig) => {
  const matchedPlaceholders = [];
  let currentLimit = longFormConfig.mpu3To5;

  placeholders.forEach((placeholder) => {
    if (
      canBeMatched(placeholder, currentLimit)
      && matchedPlaceholders.length < longFormConfig.maxAds
      && !isWithinBuffer(placeholder)
    ) {
      matchedPlaceholders.push(placeholder);
      currentLimit += getCurrentCharLimit(matchedPlaceholders.length, longFormConfig);
    }
  });

  return matchedPlaceholders;
};

/**
 * @param {Array.<Element>} placeholders - Placeholders for the ad slot
 * @param {number} ingredientsCount - Number of ingredients in the recipe page category
 * @param {RecipeCharLimitsConfig} recipeConfig - Char limits config for recipe
 *
 * @returns {Array.<Element>}
 * matched placeholders by matching their data-count attribute to characters limits
 */
export const findPlaceholdersForRecipes = (
  placeholders,
  ingredientsCount,
  recipeConfig,
) => {
  const matched = [];
  const mpu2Limit = getMpu2LvlLimit(recipeConfig, ingredientsCount);

  if ((ingredientsCount || mpu2Limit) === 0) {
    return matched;
  }

  matched.push(placeholders.find(
    (placeholder) => Number(placeholder.dataset.count) >= mpu2Limit,
  ));

  if (ingredientsCount >= 7 && matched.length === 1) {
    const mpu3Limit = recipeConfig.mpu3 + mpu2Limit;

    matched.push(placeholders.find(
      (placeholder) => Number(placeholder.dataset.count) >= mpu3Limit
        && Number(matched[0].dataset.count)
          !== Number(placeholder.dataset.count),
    ));
  }

  const matchedPlaceholder = matched.filter((placeholder) => placeholder);
  // If the matched placeholder is the final possible placeholder,
  // then set a flag that this ad is at the end of the content
  // (which we do on Recipes only)
  if (matched.length === placeholders.length) {
    matchedPlaceholder[0].endOfContent = true;
  }

  return matchedPlaceholder;
  /* eslint-disable no-else-return */
};
