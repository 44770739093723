import { debugMessage } from '../../debug.js';
import { logger } from '../../logger.js';
/**
 * Attach event handlers for creating prebid related performance markers
 */
export const attachPreBidPerformanceEventListenersOnce = (() => {
  let called = false;

  return (pbjs) => {
    if (!called) {
      called = true;

      [
        ['auctionInit', 'pb_auction_init'],
        ['auctionEnd', 'pb_auction_end'],
        ['setTargeting', 'pb_set_targeting'],
        ['requestBids', 'pb_request_bids'],
        ['addAdUnits', 'pb_add_ad_units'],
      ].forEach(([event, marker]) => {
        pbjs.onEvent(event, () => {
          const auctionEventData = {
            event: `pbDD_${marker}`,
            silenceConsole: true,
            forcePush: true,
          };
          logger.info(auctionEventData);
          debugMessage(`pbDD_${marker}`);
          performance.mark(marker);
        });
      });

      [
        ['beforeBidderHttp', 'before_bidder_http'],
        ['bidRequested', 'bid_requested'],
        ['bidResponse', 'bid_response'],
        ['bidWon', 'bid_won'],
        ['bidderError', 'bidder_error'],
        ['bidderDone', 'bidder_done'],
      ].forEach(([event, suffix]) => {
        switch (event) {
          case 'bidderError':
            pbjs.onEvent(
              event,
              ({ bidderRequest }) => {
                const bidderErrorEventData = {
                  event: `pbDD_${suffix}`,
                  bidder: bidderRequest.bidder,
                  silenceConsole: true,
                  forcePush: true,
                };
                logger.error(`prebid | prebid encountered an error with a bidder, 
                ${bidderErrorEventData}`);
                performance.mark(`pb_${bidderRequest.bidderCode.toLowerCase()}_${suffix}`);
                debugMessage(`Error: pbDD_${bidderRequest.bidderCode.toLowerCase()}_${suffix}`);
              },
            );
            break;
          case 'bidResponse':
            pbjs.onEvent(
              event,
              ({ bidResponse }) => {
                const bidResponseEventData = {
                  event: `pbDD_${suffix}`,
                  bidder: bidResponse.bidder,
                  adUnitCode: bidResponse.adUnitCode,
                  size: bidResponse.size,
                  requestTimeStamp: bidResponse.requestTimestamp,
                  responseTimeStamp: bidResponse.responseTimestamp,
                  timeTakenToRespond: bidResponse.timeToRespond,
                  cpm: bidResponse.cpm,
                  transactionId: bidResponse?.transactionId,
                  silenceConsole: true,
                  forcePush: true,
                };
                debugMessage(bidResponseEventData);
                performance.mark(`pb_${bidResponse.bidder.toLowerCase()}_${suffix}`);
                logger.info(bidResponseEventData);
              },
            );
            break;
          case 'bidWon':
            pbjs.onEvent(
              event,
              ({ bidResponse }) => {
                const bidWonEventData = {
                  event: `pbDD_${suffix}`,
                  bidder: bidResponse.bidder,
                  adUnitCode: bidResponse.adUnitCode,
                  size: bidResponse.size,
                  requestTimeStamp: bidResponse.requestTimestamp,
                  responseTimeStamp: bidResponse.responseTimestamp,
                  timeTakenToRespond: bidResponse.timeToRespond,
                  cpm: bidResponse.cpm,
                  transactionId: bidResponse?.transactionId,
                  silenceConsole: true,
                  forcePush: true,
                };
                logger.info(bidWonEventData);
                debugMessage(bidWonEventData);
              },
            );
            break;
          default:
            pbjs.onEvent(
              event,
              ({ bidderCode }) => {
                const bidEventData = {
                  event: `pbDD_${suffix}`,
                  silenceConsole: true,
                  forcePush: true,
                };
                logger.info(bidEventData);
                performance.mark(`pb_${bidderCode.toLowerCase()}_${suffix}`);
                debugMessage(`pb_${bidderCode.toLowerCase()}_${suffix}`);
              },
            );
        }
      });
    }
  };
})();
