import { debugMessage } from '../../debug.js';
import { getGlobal, setGlobal } from '../../global.js';
import { isCampaignExcluded } from './campaign.js';
import { isInstreamVideo } from './instream.js';
import { isOutOfPage } from './oop.js';
import { isPageExcluded } from './page.js';
import { isSlotElementExcluded } from './slot.js';

const STATE_KEY = 'refreshExclusions';

const getExcludedIds = () => [...(getGlobal(STATE_KEY) || [])];
const setExcludedIds = (excludedIds) => { setGlobal(STATE_KEY, new Set(excludedIds)); };

/**
 * Check if a slot should be excluded from ad refreshes.
 */
export const isSlotExcluded = (slot) => {
  const excludedIds = getExcludedIds();
  const slotId = slot.getSlotElementId();

  // If the slot has already been excluded then no need to run all the checks
  // again, or log any noisey debug messages.
  if (excludedIds.includes(slotId)) {
    return true;
  }

  const exclusionReasons = Object.entries({
    isPageExcluded,
    isSlotElementExcluded,
    isCampaignExcluded,
    isInstreamVideo,
    isOutOfPage,
  })
    .filter(([, func]) => func(slot))
    .map(([funcName]) => funcName);

  if (!exclusionReasons.length) {
    return false;
  }

  debugMessage(
    `Excluding slot from ad refreshes: ${exclusionReasons.join(', ')}`,
    { slot },
  );

  setExcludedIds([
    ...excludedIds,
    slotId,
  ]);

  return true;
};
