import { enrichWithConfig } from '../define.js';
import { getGlobalSettings } from '../settings.js';
import { initRefreshState } from '../refresh/state.js';
import { currentDeviceTypeSlot } from '../current-device-type-slot.js';
import { requestAllBids } from '../bids/request-all-bids.js';

let slots = [];
let bidsRequested = false;

/**
 * Checks if the given slot was already added to the
 * slots array.
 * @typedef {Function} SlotExists
 * @param {AddSlotParam} slot
 * @returns {boolean}
 */
const slotExists = (slot) => !!slots
  .find((queueSlot) => queueSlot.key === slot.key
    && queueSlot.index?.toString() === slot.index?.toString());

/**
 * Adds the slot by appending new slots to the
 * existing slots array. Calls requestBidsPrebid if
 * the initial call to requestBidsPrebid has been done.
 * @typedef {Function} AddSlot
 * @param {AddSlotParam} slot
 * @returns {void}
 */
export const addSlot = (slot) => {
  if (!slot || slot.key === undefined || slot.index === undefined) {
    throw new Error('Invalid data. Slot has to be an object with "key" and "index" properties');
  }

  if (slotExists(slot)) {
    return;
  }

  if (bidsRequested) {
    const config = enrichWithConfig(getGlobalSettings()?.targets?.subcat)(slot);

    if (!(config && currentDeviceTypeSlot(config))) {
      return;
    }

    initRefreshState(config);
    requestAllBids(config);
  }

  slots.push(slot);
};

/**
 * Returns slots array.
 * @typedef {Function} GetSlots
 * @returns {Array<Object>} slots array
 */
export const getSlots = () => slots;

/**
 * Clears the slots.
 * @typedef {Function} ClearSlots
 * @returns {void}
 */
export const clearSlots = () => {
  slots = [];
};

/**
 * Sets the flag that holds the information if
 * the initial call to requestBidsPrebid has been done.
 * @param {boolean} requested
 */
export const setBidsRequested = (requested = true) => {
  bidsRequested = requested;
};

/**
 * Removes the slot from the slots array.
 * @typedef {Function} RemoveSlot
 * @param {AddSlotParam} slot
 * @returns {void}
 */
export const removeSlot = (slot) => {
  slots = slots
    .filter(
      (existing) => !(existing.key === slot.key
        && existing.index?.toString() === slot.index?.toString()),
    );
};
