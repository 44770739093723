import { debugMessage } from '../../debug.js';

/**
 * Check if a slot contains an out of page ad.
 */
export const isOutOfPage = (slot) => {
  if (slot.getOutOfPage()) {
    debugMessage('Ad slot is an out of page ad', { slot });

    return true;
  }

  return false;
};
