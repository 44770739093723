import { isTamEnabled, debugMessage } from '../../debug.js';

export const initialiseTAM = () => {
  if (!isTamEnabled()) return;
  debugMessage('TAM: Amazon TAM Enabled, adding script to page');
  /**
   * Amazon APS initialisation script
   * @param {} c
   * @param {*} r
   */
  const q = (c, r) => {
    window.apstag._Q.push([c, r]);
  };
  window.apstag = window.apstag || {
    init(...args) { q('i', args); },
    fetchBids(...args) { q('f', args); },
    setDisplayBids() {},
    targetingKeys() { return []; },
    _Q: [],
  };

  // configure Amazon parameters
  const amazonConfig = {
    adServer: 'googletag',
    bidTimeout: 2e3, // set the desired timeout here
    gdpr: { cmpTimeout: 8000 }, // set the desired timeout here
    params: {},
    pubID: '3331', // replace with your unique Publisher ID
    deals: true,
  };

  // in case there is an '__uspapi' on the page we need to
  // provide 'us_privacy' param to apstag.init
  if (typeof window.__uspapi === 'function') {
    window.__uspapi('getUSPData', 1, (data, success) => {
      if (success) {
        amazonConfig.params.us_privacy = data.uspString;
      }
      window.apstag.init(amazonConfig, () => {
        debugMessage('TAM: Amazon UAM initialized, USPAPI');
      });
    });
  } else {
    window.apstag.init(amazonConfig, () => {
      debugMessage('TAM: Amazon UAM initialized, not USPAPI');
    });
  }
};
