import { retrieveContextKey } from '../api/retrieve-context-key.js';
import { sendPermutiveID } from './permutive/send-permutive-id.js';
import { logger } from '../logger.js';
/**
 * Function to retrieve a specific key:value from api context, when a callback key
 * has been provided.
 *
 * It is expected that this will contain more functions that would retrieve different keys
 * from context, and send it to other 3rd parties in the future.
 * @async
 * @returns {Promise<void>} a promise that resolves when there is a value for the key in
 * the contextApi you pass in
 */
export const identityGUIDS = async () => {
  try {
    // retrieve PermutiveGUID from setContext and send it to permutive API
    /**
     * @todo at a later date when we have more, we should seperate these calls into smaller
     * files within folders within `identity-guids`.
     */
    const permutiveUserID = await retrieveContextKey('identityGUIDS');
    sendPermutiveID(permutiveUserID);
  } catch (error) {
    logger.error(`permutive | unable to retrieve key from adManager for identityGUID, 
    {error: ${error.message}},
    {key}: permutive | identityGUIDs}`);
    throw new Error(`identityGUIDS failed - ${error}`);
  }
};
