import {
  findPlaceholdersForLongForm,
  findPlaceholdersForRecipes,
  findEmptyDivPlaceholders,
  getIngredientsCount,
  findEmptyDivPlaceholderNoDataCount,
} from './find-placeholders.js';
import { filterSlotsSizes, getHeightToReserve } from './filter-sizes.js';
import { adSlotsLazyLoad } from './ad-slots-lazy-load.js';
import {
  getDynamicAdsConfig,
  isPageTypeRecipe,
  getAdConfigsByParams,
} from './read-config.js';
import { updateAdPlaceholder } from './apply-styles.js';

import '../typedef.js';

const PLACEHOLDER_SELECTOR = 'div.ad-placement';
const LIST_SELECTOR_CLASS = 'section.recipe__ingredients li';

/**
 * @param {Array.<Element>} emptyPlaceholders - array of all placeholders on the page
 * @param {PlaceholdersMatchSettings} placeholderMatchSettings - max ads, char limit configuration
 *
 * @returns {Array.<Element>} array of matched placeholders for given configuration
 */
const matchPlaceholders = (emptyPlaceholders, placeholderMatchSettings) => {
  if (!isPageTypeRecipe(placeholderMatchSettings.contentType)) {
    return findPlaceholdersForLongForm(emptyPlaceholders, placeholderMatchSettings);
  }

  return findPlaceholdersForRecipes(emptyPlaceholders,
    getIngredientsCount(LIST_SELECTOR_CLASS), placeholderMatchSettings);
};

/**
 *
 * @param {Array.<SlotConfig>} slotConfigs - Ad slots configuration
 * Checks if any placeholder is matched and adds to gpt additional targeting
 */
export const addGlobalInlineTargeting = (slotConfigs) => {
  googletag.cmd.push(() => {
    googletag.pubads().setTargeting('kw', slotConfigs.length > 0 ? 'inline' : 'no_inline');
  });
};

/**
 * @param {PlaceholdersMatchSettings} placeholderMatchSettings - max ads, char limit configuration
 *
 * @returns {Array.<Element>} found empty div placeholders, depending on website type
 */
export const getDynamicAdsPlaceholders = (placeholderMatchSettings) => matchPlaceholders(
  findEmptyDivPlaceholders(PLACEHOLDER_SELECTOR),
  placeholderMatchSettings,
);

/**
 * @param {Array.<Element>} placeholders - matched placeholders for the ad slot
 * @param {Array.<SlotConfig>} slotsConfig - Ad slots configuration
 * @param {string} contentType - Page category describing the type of a page (recipes/non recipes)
 *
 * @returns {Array.<SlotConfig>} enriched dynamic ad slots configurations
 */
export const getEnrichedDynamicAdSlots = (placeholders, slotsConfig, contentType) => {
  addGlobalInlineTargeting(slotsConfig);
  const filteredSlotsConfigs = filterSlotsSizes(slotsConfig, window.innerWidth);

  placeholders.forEach((placeholder, index) => {
    if (filteredSlotsConfigs[index]) {
      updateAdPlaceholder(
        placeholder,
        filteredSlotsConfigs[index],
        getHeightToReserve(filteredSlotsConfigs[index]?.sizes),
        contentType,
      );
    }
  });

  return filteredSlotsConfigs;
};

/**
 * Runs logic to display dynamic ads on mobile devices
 *
 * @param {Array.<Element>} placeholders - matched placeholders for the ad slot
 * @param {string} contentType - Page category describing the type of a page (recipes/non recipes)
 */
export const loadDynamicAds = (
  placeholders,
  contentType,
) => {
  if (placeholders.length === 0) {
    addGlobalInlineTargeting([]);
    return;
  }

  const preparedAdsConfig = getDynamicAdsConfig(
    contentType,
    placeholders.length,
  );

  const dynamicAdConfigs = getEnrichedDynamicAdSlots(
    placeholders,
    preparedAdsConfig,
    contentType,
  );

  adSlotsLazyLoad(
    contentType,
    dynamicAdConfigs,
  );
};

/**
 * @param {Array<AddSlotParam>} adParams - Array with ad slot parameters key and index
 * @param {string} contentType - global settings object
 */
export const loadDynamicAdByParams = (
  adParams,
  contentType,
) => {
  const placeholders = [];
  adParams.forEach(({ key, index }) => {
    const placeholder = findEmptyDivPlaceholderNoDataCount(
      `${PLACEHOLDER_SELECTOR}-${key}--${index}`,
    );

    if (placeholder) {
      placeholders.push(placeholder);
    }
  });

  if (placeholders.length === 0) { return; }

  const preparedConfigs = getAdConfigsByParams(
    adParams,
    contentType,
  );

  const dynamicAdConfigs = getEnrichedDynamicAdSlots(
    placeholders,
    preparedConfigs,
    contentType,
  );

  adSlotsLazyLoad(
    contentType,
    dynamicAdConfigs,
  );
};
