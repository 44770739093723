import { requestBidsTAM } from './amazon-tam/request-bidders-tam.js';
import { requestBidsPrebid } from './prebid/request-bidders-prebid.js';
import { debugMessage, isTamEnabled } from '../debug.js';
import { getDefinedAdSlot, defineAdSlot } from '../define.js';
import { logger } from '../logger.js';
import { resetBiddersBack } from './utils/bid-manager.js';

/**
 * Requests bids for all slots available, with various bidding providers
 * @param {Array|Object} slots - An array of slot configurations or a single
 * slot configuration object to request bids for. Should include `callbackCounter.getGptSlots()`
 * when invoked. PreBid still triggers this on its last callback.
 * @throws {Error} If there is an error defining or displaying an ad slot.
 */
export const requestAllBids = (slots) => {
  const slotsConfigArray = Array.isArray(slots) ? slots : [slots];
  if (!slots) {
    debugMessage('TAM: No slots passed into `requestAllBids`');
    return;
  }

  // reset global state
  resetBiddersBack();

  googletag.cmd.push(() => {
    const gptAdSlots = slotsConfigArray.map((slot) => {
      // Define Ad slots if they are not already defined
      let gptAdSlot = getDefinedAdSlot(slot.id);
      if (!gptAdSlot) {
        try {
          gptAdSlot = defineAdSlot({
            id: slot.id,
            sizes: slot.sizes,
            targets: slot.targets,
            unitName: slot.unitName,
          });
          googletag.display(gptAdSlot);
        } catch (e) {
          logger.error(e);
        }
      }
      return gptAdSlot;
    });
    return gptAdSlots;
  });

  if (isTamEnabled()) {
    requestBidsTAM(slots);
  }
  requestBidsPrebid(slots);
};
