import { debugMessage } from '../debug.js';
import { getConfigValue } from '../get-config.js';
import { logger } from '../logger.js';

const LAZY_LOADING_CONFIG_KEY = 'lazyRendering';

/**
 * setupLazyLoading enables lazyLoading if it is enabled
 * @param {Object} lazyLoading
 */
export const setupGptLazyLoading = () => {
  const lazyLoading = getConfigValue(LAZY_LOADING_CONFIG_KEY);

  debugMessage('Lazy loading settings:', { lazyLoading });
  if (lazyLoading?.enabled) {
    try {
      googletag.pubads().enableLazyLoad(lazyLoading);
    } catch (error) {
      googletag.pubads().collapseEmptyDivs();
      logger.error(`Lazy Loading was not enabled or it did not load, 
      {error: ${error.message}}, 
      {key}: permutive | identityGUIDs}`);
    }
  } else {
    googletag.pubads().collapseEmptyDivs();
  }
};

/**
 * Enables all GPT services that have been defined for ad slots on the page.
 */
export const enableGptServices = () => {
  googletag.enableServices();
};
