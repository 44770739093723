import { slotRenderEnded, slotVisibilityChanged } from '../gpt/events.js';
import { debugMessage } from '../debug.js';
import { initRefreshState } from './state.js';
import { isAdRefreshEnabled } from './enabled.js';
import { watchRefreshQueue } from './queue.js';
import { refreshOnSchedule } from './schedule.js';
import { supportsPassiveEventListeners } from '../dom.js';
import { throttle } from '../throttle.js';

/**
 * Setup ad refresh.
 *
 * Creates a "state" against each ad slot that is used to watch/track it.
 *
 * Note that this setup applies to both scheduled and manual ad refreshes.
 */
export const setupAdRefresh = () => {
  initRefreshState(googletag.pubads().getSlots());

  // prepare gpt listeners
  googletag.cmd.push(() => {
    googletag.pubads().addEventListener('slotRenderEnded', slotRenderEnded);
    googletag.pubads().addEventListener('slotVisibilityChanged', slotVisibilityChanged);
  });

  watchRefreshQueue();
};

/**
 * Schedule ad refreshes, if enabled.
 */
export const setupScheduledAdRefresh = () => {
  if (!isAdRefreshEnabled()) {
    return;
  }

  const eventOpts = supportsPassiveEventListeners() ? { passive: true } : {};
  const throttledRefreshOnSchedule = throttle(refreshOnSchedule);
  const onScroll = throttle(throttledRefreshOnSchedule, 200);

  document.addEventListener('scroll', onScroll, eventOpts);

  debugMessage('Scheduled ad refresh is running');
};
