import { clear } from './clear.js';
import { merge } from './merge.js';

let context = null;

/**
 * Clears the context.
 * @typedef {Function} ClearContext
 * @param {Array<string>} paths
 * @returns {void}
 */
export const clearContext = (paths) => {
  if (!paths || !paths.length) {
    context = null;

    return;
  }

  paths.forEach(clear(context));

  if (Object.keys(context).length === 0) {
    context = null;
  }
};

/**
 * Sets the context by merging the provided value
 * with the existing context.
 * @typedef {Function} SetContext
 * @param {Object} source
 * @param {string} callback identifier to add an event listener for usage,
 * if data is retrieved async
 * @returns {void}
 */
export const setContext = (source, callback) => {
  if (!context) {
    context = source;

    return;
  }

  context = merge(context, source);
  /**
   * If a callback name has been passed, we dispatch a new event
   * with the same name, to flag when it can be retrieved from the context
   */
  if (callback) {
    window.dispatchEvent(new CustomEvent(callback));
  }
};

/**
 * Returns current context or null if context is empty.
 * @typedef {Function} GetContext
 * @returns {null|Object}
 */
export const getContext = () => context;
