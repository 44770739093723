import * as c from './constants.js';
import { hasIframeChild, applyExclusionRulesForBlockingElements, applyExclusionRulesForSemiBlockingElements } from './utils.js';

/**
 * @returns {NodeList} Node list of HTML elements with .limit-marker class
 */
export const getLimitMarkers = () => document.querySelectorAll(`.${c.LIMIT_MARKER}`);

/**
 * Get paragraph elements containig iframe
 * @returns {Array.<Element>} HTML elements containing iframe
 */
export const getIframeElements = () => [...document.querySelectorAll('p')]
  .filter((p) => hasIframeChild(p));

/**
 * @param {Array.<string>} blockingElementsTags
 * @returns {string} Selector string with .editor-content class prepended
 */
export const getBlockingElementsSelector = (blockingElementsTags) => blockingElementsTags
  .map((element) => `.${c.EDITOR_CONTENT} > ${element}`)
  .join();

/**
 * @param {Array.<Element>} limitMarkers - Character count limit HTML elements
 * @returns {Array.<Element>} Fully blocking elements (no ad slot before and after the element)
 */
export const getBlockingElements = (limitMarkers) => [...document
  .querySelectorAll(getBlockingElementsSelector(c.BLOCKING_ELEMENTS))]
  .filter(applyExclusionRulesForBlockingElements(limitMarkers));

/**
 * @param {Element} element - current HTML element
 * @returns {Element} Previous paragraph element
 */
export const getPreviousTextElement = (element) => {
  if (element.previousSibling.tagName === 'P') {
    return element.previousSibling;
  }
  return getPreviousTextElement(element.previousSibling);
};

/**
 * Get semi blocking elements (no ad before the element)
 * @param {Array.<Element>} limitMarkers - Character count limit HTML elements
 * @returns {Array.<Element>} Semi blocking elements (no ad slot before the element)
 */
export const getSemiBlockingElements = (limitMarkers) => [...document
  .querySelectorAll(
    getBlockingElementsSelector(c.SEMI_BLOCKING_ELEMENTS),
  ), ...getIframeElements()]
  .filter(applyExclusionRulesForSemiBlockingElements(limitMarkers));


/**
 * @returns {Element} The last inline ad on the page
 */
export const getLastInlineAd = () => [...document.querySelectorAll("[id*='--inline--']")].pop();

/**
 * @param {('recipe' | 'longForm')} contentType - type of page content
 * @returns {Array.<Element>} Paragraph HTML elements
 */
export const getTextElements = (contentType) => {
  if (contentType === 'recipe') {
    return [...document
      .querySelector(`.${c.RECIPE_STEPS}`)
      .querySelectorAll(`p, .${c.STEP_HEADING}`)].slice(0, -1);
  }

  const lastInlineAd = getLastInlineAd();

  if (lastInlineAd) {
    const textNodes = [...document
      .querySelectorAll(c.LONG_FORM_PARAGRAPHS)].slice(1);
    const prevTextNode = getPreviousTextElement(lastInlineAd.parentElement.parentElement);
    const bufferIndex = textNodes.indexOf(prevTextNode) - c.BUFFER_AMOUNT;
    return textNodes.slice(0, bufferIndex > 0 ? bufferIndex : 0);
  }

  return [...document
    .querySelectorAll(c.LONG_FORM_PARAGRAPHS)].slice(1, -1);
};
