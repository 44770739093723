import { removeSlot } from './api/slot.js';
import { debugMessage } from './debug.js';
import { getDefinedAdSlot } from './define.js';
import { slotIdToSlotConfig } from './slot-ids.js';

/**
 * Find and return ad slot node(s), based on the dfp-ad class.
 */
const findAdSlotNodes = (node) => {
  const adClass = 'dfp-ad';

  if (!node.tagName) {
    return []; // not an element
  }

  if (node.classList.contains(adClass)) {
    return [node];
  }

  // Multiple nodes found
  if (node.firstElementChild) {
    return [...node.getElementsByClassName(adClass)];
  }

  return [];
};

/**
 * Process any ad slot elements that were removed from the DOM.
 */
const processOldNodes = (nodes) => {
  nodes.forEach((slotElement) => {
    const slot = getDefinedAdSlot(slotElement.id);

    if (slot) {
      debugMessage('Destroying ad slot that was removed from the DOM', { slot });
      const { key, index } = slotIdToSlotConfig(slotElement.id);

      removeSlot({ key, index });

      googletag.destroySlots([slot]);

      if (window.pbjs?.[`adserverCalled-${slotElement.id}`]) {
        window.pbjs[`adserverCalled-${slotElement.id}`] = false;
      }
    }
  });
};

/**
 * Watch for any ad slots that are added dynamically.
 *
 * For example, those we slot into content loaded in the hub.
 */
export const watchForDynamicAdSlots = () => {
  const mutationCallback = (mutationsList) => {
    const oldNodes = new Set();

    // Use traditional 'for loops' for IE 11
    // eslint-disable-next-line no-restricted-syntax
    for (const { removedNodes } of mutationsList) {
      // eslint-disable-next-line no-restricted-syntax
      for (const node of removedNodes) {
        findAdSlotNodes(node).forEach((oldNode) => {
          oldNodes.add(oldNode);
        });
      }
    }

    processOldNodes([...oldNodes]);
  };

  const observer = new MutationObserver(mutationCallback);
  const container = document.body;
  const config = {
    attributes: true,
    childList: true,
    subtree: true,
  };

  observer.observe(container, config);
};
