import { clearContext, getContext, setContext } from './context.js';
import { addSlot } from './slot.js';
import { ready } from './ready.js';
import { injectDynamicAds } from './dynamic-ads.js';

/**
 * @typedef {Function} Version
 * @returns {string} The current version of the library.
 */
// eslint-disable-next-line no-undef
export const version = () => __VERSION__;

/**
 * The Public API object.
 * @type {API}
 * @typedef {Object} API
 * @property {import('./context').ClearContext} clearContext Clears the context.
 * @property {import('./context').GetContext} getContext Returns the context.
 * @property {import('./context').SetContext} setContext Sets the context.
 * @property {import('./slot').AddSlot} addSlot Adds new slot.
 * @property {import('./dynamic-ads').InjectDynamicAds} injectDynamicAds Starts dynamic
 * ad injection.
 * @property {Version} version Returns the current version of the library.
 * @property {Ready} ready Signals that the queue is ready to be processed.
 */
export const api = {
  clearContext,
  getContext,
  setContext,
  addSlot,
  injectDynamicAds,
  version,
};

api.ready = ready(api);
