import { mark } from '@immediate_media/script-utils';
import { debugMessage, isTamEnabled } from '../../debug.js';
import { setGlobal, getGlobal } from '../../global.js';
import { retryUntil } from './retry-until.js';

export const BID_PROVIDERS = {
  prebid: true,
};

// If TAM is enabled, add aps to the bid providers
if (isTamEnabled()) {
  BID_PROVIDERS.aps = true;
}

export const resetBiddersBack = () => {
  // Determine which bid providers to reset based on the keys in BID_PROVIDERS
  const bidProvidersToReset = Object.keys(BID_PROVIDERS);

  // Create an object with all bid providers set to false
  const resetStatus = bidProvidersToReset.reduce((acc, provider) => {
    acc[provider] = false;
    return acc;
  }, {});

  setGlobal('bids_back', resetStatus);
};

/**
 * Sets bid providers to be used in the bidManager logic.
 */
export const bidManager = () => {
  // Set the initial value for bid providers.
  setGlobal('bid_providers', BID_PROVIDERS);
  // Reset the status of bidders.
  resetBiddersBack();
};

/**
 * Updates the status of a specific bidder indicating bids have returned.
 * @param {string} provider - The bidder provider name.
 * @param {boolean} value - The status value indicating whether bids are back (true) or not (false).
 */
export const setBidderBack = (provider, value) => {
  setGlobal('bids_back', {
    ...getGlobal('bids_back'),
    [provider]: value,
  });
};

/**
 * Checks if all bidding providers have indicated that bids have returned.
 * @returns {boolean} - True if all bidding providers' bids have returned, otherwise false.
 */
export const getBidderStatus = () => {
  const bidProviders = Object.keys(BID_PROVIDERS);
  return bidProviders.every((provider) => getGlobal('bids_back')[provider]);
};

/**
 * Checks if all bidders have signaled bids have returned, or if a timeout has been reached,
 * or trigg the refreshing of all ad slots and logging a warning.
 * @param {Array} callbackCounterGptSlots - An array of callback counters for GPT slots.
 * @param {function} retryUntilFn - The retryUntil function for retry logic, defaults to retryUntil.
 * @returns {Promise<void>}
 */
export const allBidsBack = async (callbackCounterGptSlots, retryUntilFn = retryUntil) => {
  try {
    await retryUntilFn(() => getBidderStatus(), 1500);
    debugMessage('TAM: All bidders have come back, refreshing all slots available');
    resetBiddersBack();
    googletag.pubads().refresh(callbackCounterGptSlots);
    mark('bids-allbids-back');
  } catch (error) {
    debugMessage('TAM: Not all bids came back from Prebid and/or TAM, refreshing anyway, check the global state to see which one');
    resetBiddersBack();
    googletag.pubads().refresh(callbackCounterGptSlots);
    mark('bids-not-allbids-back');
  }
};
