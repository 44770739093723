/**
 * Receives `mantis` data from adSettings in the global.js file
 * Iterates through each property, and sends to GAM via GPT
 */

/**
 * Transforms mantis data, passing in the value label, and if you want to not use a parent key
 * @param {array} data - The data object to pass into the function
 * @param {string} valueLabel - The values key you want to return
 * @param {boolean} keyless - Whether or not the formating is looking for:
 * - true: key-data[key][valueLabel]
 * - false: [key][valueLabel]
 * @returns When keylesss is set to either:
 * - true: food_drink,food,cuisines
 * - false: anger-low,disgust-low,fear-low
 */
export const formatMantisData = (data, valueLabel, keyless) => {
  if (!data) {
    return null;
  }
  return Object.keys(data)
    .reduce((all, key) => (
      keyless ? [...all, `${data[key][valueLabel]}`] : [...all, `${key}-${data[key][valueLabel]}`]
    ), [])
    .join(',');
};

/**
 * Transforms mantis data, passing in the parent key, and its value
 * @param {array} data - The data object to pass into the function
 * @param {string} keyLabel - The parent key you want to search in
 * @param {string} valueLabel - The values key you want to return
 * @returns Default-GREEN,COVIDSafe-GREEN
 */
export const formatMantisDataNested = (data, keyLabel, valueLabel) => {
  if (!data) {
    return null;
  }
  return Object.keys(data)
    .reduce((all, key) => [...all, `${data[key][keyLabel]}-${data[key][valueLabel]}`], [])
    .join(',');
};

/**
 * Pushes mantis data into GAM via GPT.
 * Accepts the props data and then applies transformations to the
 * object to extract the key-value pairs required
 * @param {object} props - Props which are passed to this function
 */
export const setMantisData = (props) => {
  // define mantis data as empty so it can be pushed still if the corresponding data does not exist
  // define mantisCategories outside of if statement to prevent it compiling as _mantisCategories in
  const mantisGroup = [];

  // extract sentiment if sentiment exists.
  if (props?.sentiment) {
    const mantisSentiment = `sentiment-${props.sentiment}`;
    // push it to the array to send under `mantis` key.
    mantisGroup.push(mantisSentiment);
  }

  // extract emotion if emotion exists.
  if (props?.emotion) {
    const mantisEmotion = formatMantisData(props.emotion, 'level', false);
    // push it to the array to send under `mantis` key.
    mantisGroup.push(mantisEmotion);
  }

  // extract rating if rating exists.
  if (props?.ratings) {
    const mantisRating = formatMantisDataNested(props.ratings, 'customer', 'rating');
    // push it to the array to send under `mantis` key.
    mantisGroup.push(mantisRating);
  }

  /**
   * GPT will accept properties going into `mantis` and `mantis_context`.
   * mantisCategories should be `mantis_context`.
   * mantisSentiment, mantisEmotion, mantisRating should be `mantis`.
   * We only push mantis data if we have it, to keep our ad request small.
   */
  googletag.cmd.push(() => {
    if (mantisGroup.length) {
      googletag.pubads().setTargeting('mantis', mantisGroup);
    }
  });

  // extract categories if categories exist.
  if (props?.categories.mantis) {
    googletag.pubads().setTargeting(
      'mantis_context',
      formatMantisData(props.categories.mantis, 'label', true),
    );
  }
};
