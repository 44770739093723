export const slotIdToSlotConfig = (slotId) => {
  const segments = slotId.split('--');

  const prefix = segments.shift();
  const index = segments.pop();
  const key = segments.join('--');

  return {
    prefix,
    key,
    index,
  };
};
