import { debugMessage } from '../../debug.js';
/**
 * Retries an asynchronous operation until a condition is met or a timeout is reached.
 *
 * @param {Function} callbackFunction - The condition function to be evaluated.
 * @param {number} timeoutMs - The maximum time, in milliseconds, to retry until.
 * @returns {Promise} A promise that resolves when the condition is met
 * or rejects when the timeout is reached.
 * @throws {Error} Throws an error if the condition is not met within the specified timeout.
 */
export const retryUntil = async (callbackFunction, timeoutMs) => {
  const startTime = new Date().getTime();
  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      if (callbackFunction()) {
        debugMessage(`TAM: All bids came back, Resolved after: ${new Date() - startTime} ms`);

        clearInterval(interval);
        resolve();
      } else if (new Date().getTime() - startTime > timeoutMs) {
        debugMessage(`TAM: All bids did not came back, ${new Date() - startTime} ms`);
        clearInterval(interval);
        reject(new Error(`TAM: All bids did not came back, 
        Resolved after: ${new Date().getTime() - startTime} ms`));
      }
    }, 200);
  });
};
