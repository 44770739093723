import { logger } from '../logger.js';

/**
 * @typedef {import('./api').API} API
 *
 * A callback to be enqueued.
 * @typedef {Function} QueueCallback
 * @param {API} api
 * @returns {void}
 *
 * Wraps a function in a try/catch block.
 * @typedef {Function} SafeCall
 * @param {QueueCallback} fn
 * @returns {void}
 *
 * A "queue" that immediately calls enqueued functions.
 * @typedef {Object} Queue
 * @property {SafeCall} push
 */

/**
 * Returns a function that will wrap enqueued
 * callbacks in a try/catch block.
 * @param {API} api
 * @returns {SafeCall}
 */
export const safeCall = (api) => (/** @type {QueueCallback} */fn) => {
  try {
    fn(api);
  } catch (error) {
    logger.error(error);
  }
};

/**
 * Creates a queue to replace the stub array.
 * @param {API} api
 * @returns {Queue}
 */
export const queue = (api) => ({
  push: safeCall(api),
});

/**
 * Processes the queue.
 * @param {API} api
 * @param {Array<QueueCallback>} q
 */
export const process = (api, q) => {
  (q || []).forEach(safeCall(api));
};
