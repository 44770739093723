import { isMobile } from '../dom.js';
import {
  getDynamicAdsPlaceholders,
  loadDynamicAds,
  loadDynamicAdByParams,
  addGlobalInlineTargeting,
} from '../dynamic-ad-injection/define-ad-slots.js';
import {
  createPlaceholdersMatchSettings,
  isPageTypeRecipe,
} from '../dynamic-ad-injection/read-config.js';
import { getGlobalSettings } from '../settings.js';

/**
 * @param {object} settings
 * @returns {boolean} true if in global settings dynamic ad injection is enabled
 */
export const isDynamicAdsEnabled = (settings) => {
  const requiredRecipeFileds = ['mpu2Lvl1', 'mpu2Lvl2', 'mpu2Lvl3', 'mpu3'];
  const requiredArticles = ['mpu3To5', 'mpu6Plus'];
  const settingsFields = Object.keys(settings);

  if (
    isPageTypeRecipe(settings.contentType)
    && !requiredRecipeFileds.every((field) => settingsFields.includes(field))
  ) return false;

  if (
    !isPageTypeRecipe(settings.contentType)
    && !requiredArticles.every((field) => settingsFields.includes(field))
  ) return false;

  return true;
};

/**
 * Starts the process of dynamic ads injection (placeholder matching,
 * height reservation, styling). Should be called after React hydration
 * has finished.
 * @typedef {Function} InjectDynamicAds
 * @returns {void}
 */
export const injectDynamicAds = () => {
  const globalSettings = getGlobalSettings();
  const placeholderMatchSettings = createPlaceholdersMatchSettings(globalSettings);

  const { contentType, desktopEnabled } = placeholderMatchSettings;

  if (!isPageTypeRecipe(contentType)) {
    loadDynamicAdByParams(
      [{
        key: 'inline',
        index: 2,
      }],
      contentType,
    );
  }

  if (!isMobile() && !desktopEnabled) {
    addGlobalInlineTargeting([]);
    return;
  }

  if (isDynamicAdsEnabled(placeholderMatchSettings)) {
    const placeholders = getDynamicAdsPlaceholders(
      placeholderMatchSettings,
    );

    loadDynamicAds(
      placeholders,
      contentType,
    );
  }

  addGlobalInlineTargeting([]);
};
