import '../typedef.js';

import { enrichWithConfig } from '../define.js';
import { getConfigValueForContentType } from '../get-config.js';

const AD_INDEX_OFFSET = 5;

/**
 * @param {AdSlot} slot - ad slot configuration
 *
 * @returns {AdTargets} targeting for GAM
 */
const enrichSlotTargets = (slot) => {
  const { targets } = slot;
  const enriched = {
    ...targets,
    pos: [
      ...targets?.pos,
    ],
  };

  if (!enriched.pos.includes('dai')) {
    enriched.pos.push('dai');
  }

  return enriched;
};

/**
 * @param {string} pageType - subcategory type from global settings
 *
 * @returns true if page type is recipe
 */
export const isPageTypeRecipe = (pageType) => pageType === ('recipe' || 'recipes');

/**
 * @param {Object} globalSettings - page global settings
 *
 * @returns {string} type of the page from globalSettings object
 */
export const getPageType = (globalSettings) => globalSettings.targets?.subcat
  || globalSettings.targets?.channel;

/**
 * @param {number} slotsRequiredNumber - number of placeholders matched for page
 * @param {string} key - ad config kye (inline etc.)
 * @param {string} contentType - page subcategory
 *
 * @returns {Array<AddSlotParam>} - names of the slots configurations needed
 */
export const getSlotsNamesSearch = (slotsRequiredNumber, key) => new Array(
  slotsRequiredNumber,
)
  .fill({ key })
  .map((_, index) => ({
    key,
    index: index + AD_INDEX_OFFSET,
  }));

/**
 * @param {Array<AddSlotParam>} adParams - names of the slots configurations needed
 * @param {string} contentType - page subcat
 *
 * @returns {Array<SlotConfig>} transformed single ad config
 */
export const getAdConfigsByParams = (
  adParams,
  contentType,
) => adParams.map(enrichWithConfig(contentType))
  .filter((slot) => slot);

/**
 * @param {string} contentType - global settings object
 * @param {number} slotsRequiredNumber - number of placeholders matched for page
 *
 * @returns {Array<SlotConfig>} filtered and transformed dynamic ads config
 */
export const getDynamicAdsConfig = (
  contentType,
  slotsRequiredNumber,
) => {
  const slotConfigs = getSlotsNamesSearch(
    slotsRequiredNumber,
    'inline',
    contentType,
  );

  return slotConfigs
    .map(enrichWithConfig(contentType))
    .filter((slot) => slot)
    .map((slot) => ({
      ...slot,
      targets: enrichSlotTargets(slot),
    }));
};

/**
 * @param {Object} globalSettings - page global settings
 *
 * @returns {Object} configuration (char limit, max ads, page type) for dynamic ads injection
 */
export const createPlaceholdersMatchSettings = (globalSettings) => (({
  ...getConfigValueForContentType(
    'dai',
    getPageType(globalSettings),
  ),
  contentType: getPageType(globalSettings),
}));
