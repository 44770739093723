import { isFluid } from '../../dynamic-ad-injection/filter-sizes.js';

/**
 * Sorts two sizes descending by the viewport size
 * @param {Sizes} sizeA - array where the first element indicates
 * viewport size and the second holds associated sizes
 * @param {Sizes} sizeB - array where the first element indicates
 * viewport size and the second holds associated sizes
 * @returns {number} value >0 when the viewport of sizeB is bigger than
 * the viewport of sizeA, 0 when they are equal, value <0 otherwise
 */
const viewportDesc = (sizeA, sizeB) => {
  const viewportA = sizeA[0];
  const viewportB = sizeB[0];

  // Similar to how the 'gpt' destination sorts viewport sizes
  return (
    viewportB?.[0] * viewportB?.[1] - viewportA?.[0] * viewportA?.[1]
    || viewportB?.[0] - viewportA?.[0]
    || viewportB?.[1] - viewportA?.[1]
  );
};

/**
 * Selects the sizes applicable for current viewport
 * @param {Sizes} size - array where the first element
 * indicates viewport size and the second holds associated sizes
 * @returns {Sizes} sizes applicable for current viewport
 */
const currentViewport = (size) => {
  const viewportWidth = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0,
  );
  const viewportHeight = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0,
  );
  const sizeViewport = size[0];

  return viewportWidth >= sizeViewport[0] && viewportHeight >= sizeViewport[1];
};

/**
 * Returns sizes associated with the biggest possible viewport
 * and removes non-numeric sizes (fluid) as they are not
 * supported by PPI
 * @param {(Sizes | Array.<Sizes>)} - sizes array of arrays, where first element
 * indicates viewport size and the second holds associated sizes
 * @returns array of sizes for current viewport
 */
export const getSizesForCurrentViewport = (sizes = []) => sizes
  .sort(viewportDesc)
  .find(currentViewport)?.[1]
  ?.filter((size) => !isFluid(size));
