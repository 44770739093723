import { getSlotElementId } from '../element-ids.js';
import { getGlobal, setGlobal } from '../global.js';

const STATE_KEY = 'refreshState';

const getRefreshState = () => getGlobal(STATE_KEY) || {};
const setRefreshState = (state) => { setGlobal(STATE_KEY, state); };

/**
 * Get the state for a given slot.
 */
export const getSlotState = (slot) => {
  const globalState = getRefreshState();
  const slotElementId = getSlotElementId(slot);

  if (!slotElementId) {
    return {};
  }

  return globalState[slotElementId] || {};
};

/**
 * Update the state for a given slot.
 */
export const updateSlotState = (slot, state) => {
  const currentSlotState = getSlotState(slot);
  const slotElementId = getSlotElementId(slot);

  if (!slotElementId) {
    return;
  }

  const newState = {
    ...currentSlotState,
    ...state,
  };

  setRefreshState({
    ...getRefreshState(),
    [slotElementId]: newState,
  });
};

/**
 * Initialise the refresh state for the given slot(s).
 */
export const initRefreshState = (slots) => {
  const arr = Array.isArray(slots) ? slots : [slots];

  const currentState = getRefreshState();
  const newState = arr.reduce((acc, slot) => {
    const slotElementId = getSlotElementId(slot);

    if (!slotElementId) {
      return acc;
    }

    return {
      ...acc,
      [slotElementId]: {},
    };
  }, {});

  if (!Object.keys(newState).length) {
    return;
  }

  setRefreshState({
    ...currentState,
    ...newState,
  });
};
