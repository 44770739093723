import { getSettings } from '@immediate_media/script-utils';
import { getContext } from './api/context.js';
import { logger } from './logger.js';

export const AWAIT_SETTINGS_ATTEMPTS = 10;
const AWAIT_SETTINGS_INTERVAL = 200;

/**
 * Get the global settings.
 *
 * Settings are read from the `pageInfo` to support of the legacy "Fabric 1"
 * pages, and from the api context with a fallback to `adSettings` for
 * our new "Fabric 2" pages.
 * @returns {object} The global settings.
 */
export const getGlobalSettings = () => {
  const context = getContext();
  const pageInfo = getSettings(['adSettings', 'pageInfo']);

  return context || pageInfo;
};

export const objectHasPath = (object) => (path) => {
  const pathParts = path.split('.');
  let obj = object;

  for (let i = 0; i < pathParts.length; i += 1) {
    if (obj && !Object.hasOwnProperty.call(obj, pathParts[i])) {
      return false;
    }

    obj = obj[pathParts[i]];
  }

  return true;
};

export const objectHasPaths = (globalSettings, requiredPaths) => {
  if (!globalSettings) {
    return false;
  }

  return requiredPaths.every(objectHasPath(globalSettings));
};

export const awaitGlobalSettings = (requiredPaths, callback) => {
  (function attempt(times) {
    if (times <= 0) {
      callback(new Error(
        'Ads could not get globalSettings, '
         + `globalSettings not retrieved after ${AWAIT_SETTINGS_ATTEMPTS} attempts`,
      ));

      return;
    }

    const globalSettings = getGlobalSettings();

    if (objectHasPaths(globalSettings, requiredPaths)) {
      callback(null, globalSettings);

      return;
    }

    logger.error(`globalSettings invalid, expected paths: ${requiredPaths.join(', ')}`);

    setTimeout(() => {
      attempt(times - 1);
    }, AWAIT_SETTINGS_INTERVAL);
  }(AWAIT_SETTINGS_ATTEMPTS));
};
