import {
  hasLimitMarkerChild, siblingIsBlockingElement, isHtmlTag, isLimitMarkerText, htmlRegex,
  getNextEditorContent, isSiblingAdPlacement,
} from './utils.js';
import * as c from './constants.js';

const baseStyles = `
  color: rgb(51, 51, 51);
  opacity: 0.9;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
`;

const globalSetingsIndicatorStyles = `
  background-color: gold;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 999;
  padding: 15px;
  pointer-events: none;
  ${baseStyles}
`;

/**
 * Creates new DOM element with global ad settings indicator
 * @returns {Element} Global settings indicator
 */
export const createGlobalSettingsIndicator = () => {
  const globalSettingsIndicator = document.createElement('div');

  globalSettingsIndicator.setAttribute('id', c.STITCH_GLOBAL_SETTINGS);
  globalSettingsIndicator.setAttribute('style', globalSetingsIndicatorStyles);

  return globalSettingsIndicator;
};

/**
 * Populates global settings indicator with content
 * and adds it to the DOM
 * @param {Array.<{ param: String; value: (String | Number) }>} params - Global settings parameters
 */
export const appendGlobalSettingsIndicator = (params) => {
  const globalSettingsIndicator = createGlobalSettingsIndicator();
  let settings = '';

  params.forEach((param) => {
    settings += `<p style="margin:0;padding=0">${param.label}: ${param.value}</p>`;
  });

  globalSettingsIndicator.innerHTML = settings;
  document.getElementById('__next').appendChild(globalSettingsIndicator);
};

const adSlotMarkerStyles = `
  display: block;
  background-color: lightgreen;
  padding: 0 10px;
  width: 100%;
  margin: 0;
  ${baseStyles}
`;

/**
 * @returns {Element} ad slot marker HTML element
 */
export const getAdSlotMarker = () => {
  const adSlotMarker = document.createElement('span');
  adSlotMarker.innerText = 'Ad slot';
  adSlotMarker.setAttribute('style', adSlotMarkerStyles);
  adSlotMarker.classList.add(c.AD_SLOT_MARKER);

  return adSlotMarker;
};

/**
 * Iterate over text elements and inject ad slot pointer tag if
 * the element contains tag pointer and the next element is not
 * a blocking element
 * @param {HTMLCollection} textElements - Paragraph HTML elements
 * @param {Array.<Element>} blockingElements - Blocking HTML elements
 * @param {Array.<Element>} semiBlockingElements - Semi-blocking HTML elements
 * @param {Array.<Element>} limitMarkers - Character count limit HTML elements
 */
export const injectAdSlotMarkers = (
  textElements, blockingElements, semiBlockingElements, limitMarkers,
) => {
  [...textElements].forEach((element) => {
    const isSiblingBlockingElement = [...blockingElements].find(siblingIsBlockingElement(element));

    if (!hasLimitMarkerChild(element, limitMarkers) || isSiblingBlockingElement) {
      return;
    }

    const adSlotMarker = getAdSlotMarker();
    const isSiblingSemiBlockingElement = [...semiBlockingElements]
      .find(siblingIsBlockingElement(element));

    if (!isSiblingSemiBlockingElement) {
      element.after(adSlotMarker);
      return;
    }

    if (isSiblingAdPlacement(element)) {
      element.nextElementSibling.nextElementSibling.after(adSlotMarker);
      return;
    }

    const elementIsInNextEditorContent = !element.nextElementSibling;
    const nextEditorContent = getNextEditorContent(element);

    if (elementIsInNextEditorContent && nextEditorContent) {
      nextEditorContent.children[0].after(adSlotMarker);
      return;
    }

    element.nextElementSibling.after(adSlotMarker);
  });
};

const limitTagStyles = `
  position: absolute; 
  background-color: gold; 
  pointer-events: none; 
  padding: 0 10px; 
  text-align: center; 
  width: 128px; 
  margin: 0; 
  z-index: 2;
  ${baseStyles}
`;

export const limitMarker = `<span style="${limitTagStyles}" `
+ `class=${c.LIMIT_MARKER}>${c.LIMIT_REACHED}</span>`;

/**
 *  Iterate over text and inject limit marker
 * @param {Element} textElement - Paragraph HTML elements
 * @param {number} charCountLimit - Character count limit
 */
export const injectLimitMarker = (textElement, charCountLimit) => {
  const { innerHTML } = textElement;
  const htmlParts = innerHTML.split(htmlRegex);
  let charCount = 0;

  htmlParts.forEach((part, index) => {
    if (!isHtmlTag(part) && !isLimitMarkerText(part)) {
      [...part].forEach((_, charIndex) => {
        if (charCount === charCountLimit - 1) {
          htmlParts[index] = htmlParts[index].slice(0, charIndex + 1)
            + limitMarker
            + htmlParts[index].slice(charIndex + 1);
        }
        charCount += 1;
      });
    }
  });

  const modifiedHTML = htmlParts.reduce((curr, acc) => curr + acc);

  // give the parent element relative position to allow
  // absolute positioning of the limit tag
  textElement.parentNode.setAttribute('style', 'position: relative;');
  // eslint-disable-next-line no-param-reassign
  textElement.innerHTML = modifiedHTML;
  textElement.setAttribute('style', 'margin-bottom: 0px');
};

/**
 * @param {Element} blockingElement - Blocking HTML element
 * @returns {string} CSS styles for blocking element indicator
 */
const getBlockingElementStyles = (blockingElement) => `
  position: absolute;
  display: block;
  height: ${blockingElement.offsetHeight}px;
  width: 100%;
  background-color: tomato;
  top: ${blockingElement.offsetTop}px;
  left: ${blockingElement.offsetLeft}px;
  padding: 15px;
  z-index: 1;
  pointer-events: none;
  margin: 0;
  ${baseStyles}
`;

/**
 * @param {Array.<Element>} blockingElements - Blocking HTML elements
 */
export const createBlockingElementIndicators = (blockingElements) => {
  // remove all blocking element indicators of resize event
  const blockingElementIndicators = document.querySelectorAll(`.${c.BLOCKING_ELEMENT}`);
  blockingElementIndicators.forEach((element) => element.remove());

  blockingElements.forEach((element) => {
    const blockingElementIndicator = document.createElement('div');

    blockingElementIndicator.classList.add(c.BLOCKING_ELEMENT);
    blockingElementIndicator.setAttribute('style', getBlockingElementStyles(element));
    blockingElementIndicator.textContent = 'Blocking element';

    element.parentNode.setAttribute('style', 'position: relative;');
    element.parentNode.appendChild(blockingElementIndicator);
  });
};
