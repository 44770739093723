import { getScheduledRefreshTargetTime } from './config.js';
import { debugMessage } from '../debug.js';
import { queueForRefresh } from './queue.js';
import { wasSlotViewable } from './viewability.js';
import { isSlotExcluded } from './exclusion/index.js';

/**
 * Handle scheduled ad refreshes.
 */
export const refreshOnSchedule = () => {
  const targetTime = getScheduledRefreshTargetTime();


  googletag
    .pubads()
    .getSlots()
    .filter((slot) => !isSlotExcluded(slot))
    .filter((slot) => wasSlotViewable(slot, targetTime))
    .forEach((slot) => {
      debugMessage('Scheduled ad refresh triggered', { slot });

      // This target is used by ad ops to track scheduled ad refreshes.
      slot.setTargeting('ad-refresh', 'true');

      queueForRefresh(slot);
    });
};
