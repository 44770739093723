import { inferDeviceTypeFromViewport } from '../dom.js';
/**
 * Sets page-level targets in GPT
 * @param {Object} globalSettings - Global settings containing 'targets' property
 */
export const setupPageLevelTargeting = (globalSettings) => {
  googletag.pubads().setTargeting('deviceType', inferDeviceTypeFromViewport());

  if (globalSettings?.targets) {
    Object.entries(globalSettings?.targets)
      .forEach(([key, value]) => {
        googletag.pubads().setTargeting(key, value);
      });
  }
};
