import { debugMessage } from '../../debug.js';
import { getExcludedCampaigns } from '../config.js';
import { getSlotState } from '../state.js';

/**
 * Check if the campaign is excluded.
 */
export const isCampaignExcluded = (slot) => {
  const { campaignId } = getSlotState(slot);

  if (getExcludedCampaigns().includes(campaignId)) {
    debugMessage('Ad slot campaign ID matches exclusion list', { slot });

    return true;
  }

  return false;
};
