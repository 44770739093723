import { logger } from '../logger.js';

const isPermutiveSettingsSet = () => window.permutive;

const isGptPermutiveTargetingSet = () => googletag.pubads().getTargeting('permutive').length;

/**
 * Get the permutive segments
 */
const getPermutiveSegments = () => {
  try {
    return window.localStorage._psegs ? JSON.parse(window.localStorage._psegs) : [];
  } catch (error) {
    logger.warn(`Error processing permutive segments for PreBid, 
    {error: ${error.message}, 
    {key}: permutive | prebid}`);
    return [];
  }
};

/**
 * Get permutive targeting info from local storage and push to gpt.
 */
export const setupPermutiveTargeting = () => {
  if (isPermutiveSettingsSet() && !isGptPermutiveTargetingSet()) {
    const kvs = localStorage.getItem('_pdfps') || '';
    googletag.pubads().setTargeting('permutive', kvs ? JSON.parse(kvs) : []);
  }
};

/**
 * Add the number of permutive segments.
 */
export const addPermutiveSegmentsToLux = () => {
  const psegs = getPermutiveSegments();

  window.LUX.addData('permutive-segments', psegs.length);
};
