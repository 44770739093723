import { isAdRefreshEnabled } from './enabled.js';

/**
 * Reset prebid state for the slot with given id so that future bids can execute.
 */
export const resetPrebid = (id) => {
  if (!isAdRefreshEnabled()) {
    return;
  }

  if (window.pbjs) {
    window.pbjs[`adserverCalled-${id}`] = false;
  }
};
