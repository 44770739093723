import * as c from './constants.js';

export const continueLoop = () => true;
export const breakLoop = () => false;

/**
 * Get number of characters of a text element
 * @param {Element} element - HTML text element
 * @returns {number} Number of characters in a text element
 */
export const getElementLength = (element) => element.textContent.length;

/**
 * Check if element contains limit marker. WEB API method .contains is used to
 * ensure that limit markers are found when they are nested deeper in DOM tree
 * @param {Element} element - HTML text element
 * @param {Array.<Element>} limitMarkers - Character count limit HTML elements
 * @returns {boolean} Whether the element contains a limit marker child
 */
export const hasLimitMarkerChild = (element, limitMarkers) => {
  const hasMarker = Boolean([...limitMarkers]
    .map((marker) => element.contains(marker))
    .find((marker) => marker === true));

  return hasMarker;
};

/**
 * Check if element has an iframe child
 * @param {Element} element - HTML element
 * @returns {boolean} Whether the element contains a iframe child
 */
export const hasIframeChild = (element) => Boolean(
  element.children.length
    && [...element.children].find((child) => child.tagName === 'IFRAME'),
);

/**
 * @param {Element} element - current HTML element
 * @returns {boolean} Whether the next sibling is an ad placement
 */
export const isSiblingAdPlacement = (element) => element
  .nextElementSibling?.className === c.AD_PLACEMENT;

/**
 * @param {Element} element - current HTML element
 * @returns {Element} Next .editor-content element
 */
export const getNextEditorContent = (element) => {
  const postContent = document.querySelector(`.${c.POST_CONTENT}`);
  const editorContents = [...postContent.children].filter((child) => [...child.classList]
    .includes(c.EDITOR_CONTENT));
  const currentEditorContentIndex = editorContents.indexOf(element.parentNode);
  return editorContents[currentEditorContentIndex + 1];
};

/**
 * @param {Element} element - current HTML element
 * @returns {boolean} Whether the element's sibling is a blocking element
 */
export const siblingIsBlockingElement = (element) => (blockingElement) => {
  // if element has no next sibling look for siblings in next .editor-content
  if (!element.nextElementSibling) {
    const postContent = document.querySelector(`.${c.POST_CONTENT}`);
    const editorContents = [...postContent.children].filter((child) => [...child.classList]
      .includes(c.EDITOR_CONTENT));
    const currentEditorContentIndex = editorContents.indexOf(element.parentNode);
    const nextEditorContent = editorContents[currentEditorContentIndex + 1];

    return blockingElement === nextEditorContent?.children[0];
  }

  // omit the ad-placement if it occurs and grab the element next to it
  if (isSiblingAdPlacement(element)) {
    if (!element.nextElementSibling.nextElementSibling) {
      const nextEditorContent = getNextEditorContent(element);

      return blockingElement === nextEditorContent?.children[0];
    }

    return blockingElement === element.nextElementSibling.nextElementSibling;
  }

  return blockingElement === element.nextElementSibling;
};

export const htmlRegex = /(<[^>]+>)/g;

/**
 * Check if text content part is a HTML tag
 * @param {string} part - part of element's text content
 * @todo The method of testing if text is a HTML tag requires revision
 * @returns {boolean} Whether the part is a HTML tag
 */
export const isHtmlTag = (part) => htmlRegex.test(part);

/**
 * @param {string} part - part of element's text content
 * @returns {boolean} Whether the part is a limit marker text content
 */
export const isLimitMarkerText = (part) => part.includes(c.LIMIT_REACHED);

/**
 * Used when text nodes are distrubuted across two or more .editor-content containers
 * @param {Element} element - HTML element
 * @returns {Element} Closest HTML text element from previous .editor-content
 */
export const getLastTextElementFromPrevEditorContent = (element) => {
  const postContent = document.querySelector(`.${c.POST_CONTENT}`);
  const editorContents = [...postContent.children]
    .filter((child) => [...child.classList].includes(c.EDITOR_CONTENT));
  const currentEditorContentIndex = editorContents.indexOf(element.parentNode);
  const prevEditorContent = editorContents[currentEditorContentIndex - 1];
  const textElements = [...prevEditorContent.children].filter((child) => child.tagName === 'P');

  return textElements[textElements.length - 1];
};
/**
 * @param {Element} element - HTML element
 * @returns {Element} Previous sibling element. If the first prepending element is a ad-placement
 * it omits this element and grabs next one to it.
 */
const getPreviousElement = (element) => {
  if (!element.previousElementSibling) return null;

  return element.previousElementSibling.className === c.AD_PLACEMENT
    ? element.previousElementSibling.previousElementSibling
    : element.previousElementSibling;
};

/**
 * Checks if previous element contains a limit marker
 * @param {Element} element - HTML element
 * @param {Array.<Element>} limitMarkers - Character count limit HTML elements
 * @returns {boolean} Whether the previous element contains a limit marker
 */
const previousElementContainsLimitMarker = (element, limitMarkers) => {
  const previousElement = getPreviousElement(element);

  return element.previousElementSibling
    ? hasLimitMarkerChild(previousElement, limitMarkers)
    : hasLimitMarkerChild(getLastTextElementFromPrevEditorContent(element), limitMarkers);
};

/**
 * @param {Element} element - HTML element
 * @returns {boolean} Whether the element is a list type
 */
const isList = (element) => element.tagName === 'UL' || element.tagName === 'OL';

/**
 * @param {Element} element - HTML element
 * @param {Array.<Element>} limitMarkers - Character count limit HTML elements
 * @returns {boolean} Whether the list is blocking an ad slot
 */
export const isBlockingList = (element, limitMarkers) => isList(element)
  && previousElementContainsLimitMarker(element, limitMarkers);

/**
 * @param {Element} element - HTML element
 *  * @returns {boolean} Whether the element is a video type
 */
const isVideo = (element) => hasIframeChild(element)
  || [...element.classList].includes(c.MEDIA_PLAYER)
  || [...element.classList].includes(c.BRIGHTCOVE);

/**
 * @param {Element} element - HTML element
 * @param {Array.<Element>} limitMarkers - Character count limit HTML elements
 * @returns {boolean} Whether the video is blocking an ad slot
 */
export const isBlockingVideo = (element, limitMarkers) => isVideo(element)
     && previousElementContainsLimitMarker(element, limitMarkers);

/**
 * @param {Element} element - HTML element
 * @returns {boolean} Whether the element is an ad slot
 */
const isAdSlot = (element) => [...element.classList].includes(c.AD_SLOT);

/**
 * @param {Element} element - HTML element
 * @param {Array.<Element>} limitMarkers - Character count limit HTML elements
 * @returns {boolean} Whether the ad slot is blocking an dynamic ad slot
 */
export const isBlockingAdSlot = (element, limitMarkers) => isAdSlot(element)
  && previousElementContainsLimitMarker(element, limitMarkers);

/**
 * Check if element is a fully blocking element
 * @param {Array.<Element>} limitMarkers - Character count limit HTML elements
 * @returns {Function} Function testing whether the element is fully blocking the ad slot
 */
export const applyExclusionRulesForBlockingElements = (limitMarkers) => {
  /**
   * @param {Element} element - HTML element
   * @returns {boolean} Whether element is fully blocking the ad slot
   */
  const isBlocking = (element) => isBlockingAdSlot(element, limitMarkers);

  return isBlocking;
};

/**
 * Check if element is a semi blocking element
 * @param {Array.<Element>} limitMarkers - Character count limit HTML elements
 * @returns {Function} Function testing whether the element is semi blocking the ad slot
 */
export const applyExclusionRulesForSemiBlockingElements = (limitMarkers) => {
  /**
   * @param {Element} element - HTML element
   * @returns {boolean} Whether element is semi blocking the ad slot
   */
  const isSemiBlocking = (element) => isBlockingList(element, limitMarkers)
  || isBlockingVideo(element, limitMarkers);

  return isSemiBlocking;
};
