import { safeParseJSON } from '@immediate_media/script-utils';
import { logger } from './logger.js';

export const onInteractive = (callback) => {
  if (['interactive', 'complete'].includes(document.readyState)) {
    callback();

    return;
  }

  document.addEventListener('readystatechange', () => {
    if (document.readyState === 'interactive') {
      callback();
    }
  });
};

/**
 * Parse the data from an ad slot element.
 */
export const loadSlotElement = (el) => {
  if (!el.id.length) {
    logger.warn('An ad slot element is missing an id attribute');
    return {};
  }

  return {
    id: el.id,
    targets: safeParseJSON(el.dataset.adtargets) || {},
    sizes: safeParseJSON(el.dataset.adsizes) || [],
    unitName: el.dataset.unitname,
  };
};

/**
 * Get the details of each ad slot on the page.
 */
export const getAdSlotsOnPage = () => {
  const adSelector = '.dfp-ad';
  const slots = [...document.querySelectorAll(adSelector)];

  return slots.map(loadSlotElement);
};

/**
 * Infer device type from viewport width.
 *
 * The breakpoints match those of our responsive ads, rather than our CSS.
 */
export const inferDeviceTypeFromViewport = (viewportBreakpoints = {
  desktop: 970,
  tablet: 768,
  mobile: 0,
}) => {
  const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

  const deviceType = Object
    .keys(viewportBreakpoints)
    .find((bp) => viewportWidth >= viewportBreakpoints[bp]);

  return deviceType;
};

/**
 * @returns {boolean} checks if the device is mobile type or not.
 */
export const isMobile = () => (/Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));

/**
 * Check if passive event listeners are supported by the current browser.
 *
 * https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md#feature-detection
 */
export const supportsPassiveEventListeners = () => {
  let isSupported = false;

  try {
    const opts = Object.defineProperty({}, 'passive', {
      // eslint-disable-next-line getter-return
      get() {
        isSupported = true;
      },
    });
    window.addEventListener('testPassive', null, opts);
    window.removeEventListener('testPassive', null, opts);
  } catch (error) {
    logger.warn(`Passive events not supported, {error: ${error.message}}`);
  }

  return isSupported;
};
