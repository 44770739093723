import { debugMessage } from '../../debug.js';

/**
 * Check if a specific slot element has been excluded.
 */
export const isSlotElementExcluded = (slot) => {
  const slotElement = document.getElementById(slot.getSlotElementId());

  if (slotElement?.dataset.adrefresh === 'false') {
    debugMessage('Ad slot has been intentionally excluded from refreshes', { slot });

    return true;
  }

  return false;
};
