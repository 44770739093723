/**
 * Get the global state from the `window.IM.advertising` object.
 *
 * If the state does not exist yet it will be created.
 */
const getGlobalAdvertisingObject = () => {
  window.IM = window.IM || {};
  window.IM.advertising = window.IM.advertising || {};

  return window.IM.advertising;
};

/**
 * Set some global state aganist the `window.IM.advertising` object.
 */
const setGlobalAdvertisingObject = (obj) => {
  const state = getGlobalAdvertisingObject();

  if (obj) {
    Object.assign(state, obj);
  }
};

/**
 * Get a particular item from the global state.
 *
 * If the item does not exist yet it will be created.
 */
export const getGlobal = (key) => {
  if (!getGlobalAdvertisingObject()[key]) {
    setGlobalAdvertisingObject({ [key]: null });
  }

  return getGlobalAdvertisingObject()[key];
};

/**
 * Set the value for a particular item in the global state.
 */
export const setGlobal = (key, value) => {
  setGlobalAdvertisingObject({ [key]: value });
};
