import '../typedef.js';
import { isMobile } from '../dom.js';
import { isPageTypeRecipe } from './read-config.js';

const adDisclaimerStyle = `
  text-align: center; 
  font-family: Helvetica,Arial,sans-serif!important;
  font-size: 10px!important;
  box-shadow: none!important;
  color: #3d3d3d;
  height: 30px;
  margin: auto;
  line-height: 30px;  
`;

const adSlotContainerStyle = `
  position: sticky;
  top: 44px;
  width: 300px;
  height: fit-content;
  background: #CCCCCC;
  margin: auto;
`;

const placeholderStyle = (reservedHeight) => {
  const styles = ` 
    margin-left: -10px; 
    background: rgb(245, 245, 245); 
    padding-bottom: 30px;
    ${reservedHeight > 0 && `height: ${reservedHeight + 30}px;`}
  `;

  if (isMobile()) {
    return styles.concat('\n', 'width: 100vw;');
  }

  return styles;
};

/**
 * @param {string} pageCat - page category
 * @returns {string} text for ad disclaimer
 */
const getAdDisclaimerText = (pageCat) => `${isPageTypeRecipe(pageCat)
  ? 'Recipe' : 'Article'} continues below advert`;

/**
 * Updates placeholder HTML code
 *
 * @param {Element} placeholder - dev placeholder
 * @param {SlotConfig} slotConfig - dynamic ad slot configuration
 * @param {number} reservedHeight - chosen height to be reserved for the placeholder
 * @param {string} pageCat - page category
 */
export const updateAdPlaceholder = (placeholder, slotConfig, reservedHeight, pageCat) => {
  if (!placeholder || !slotConfig || !reservedHeight || !pageCat) { return; }

  const adDisclaimer = document.createElement('div');
  const adSlotContainer = document.createElement('div');
  const adSlotElement = document.createElement('div');

  adDisclaimer.setAttribute('style', adDisclaimerStyle);

  // Only apply the Ad Disclaimer Text if this slot does not have the "endOfContent" flag
  // as on recipes, we can show an ad at the end of a recipe (so it doesn't "continue below")
  let disclaimerText;
  if (slotConfig.showDisclaimer) {
    disclaimerText = placeholder.endOfContent ? 'Advertisement' : getAdDisclaimerText(pageCat);
    adDisclaimer.innerText = disclaimerText;
    // Adding to a data attribute to support unit tests
    adSlotElement.setAttribute('data-adtext', disclaimerText);
  }

  adSlotContainer.setAttribute('style', adSlotContainerStyle);
  adSlotElement.setAttribute('id', slotConfig.id);

  placeholder.setAttribute('style', placeholderStyle(reservedHeight));
  placeholder.appendChild(adDisclaimer);
  placeholder.appendChild(adSlotContainer);

  adSlotContainer.appendChild(adSlotElement);
};
