import '../typedef.js';

/**
 * @param {AdSize} size - Ad slot width and height
 * @returns {boolean} returns true if given array is valid size, format [width, height]
 */
const isSizeTuple = (size) => Array.isArray(size)
  && size.length === 2
  && size.every((element) => typeof element === 'number');

/**
 * @param {(AdSize | string)} size - Ad slot width and height
 * @returns {boolean} checks if the size is fluid
 */
export const isFluid = (size) => size === 'fluid';

/**
 * @param {(AdSize | string)} size - Ad slot width and height
 * @returns {boolean} true if value in array is valid size
 */
const isSize = (size) => isFluid(size) || isSizeTuple(size);

/**
 * @param {(AdSize | string)} size - Ad slot width and height
 * @param {number} width - Ad slot width
 * @returns {boolean} true if size is not greater than screen width
 */
const sizeMatches = (size, width) => {
  if (isSizeTuple(size)) {
    return size[0] <= width;
  }

  return true;
};

/**
 * @param {Array} array - An array to be tested whether it's empty or not
 * @returns {boolean} true if array is empty, or false if it's not
 */
const isEmptyArray = (array) => Array.isArray(array) && array.length === 0;

/**
 * @param {AdSizes} sizes - A collection of ad slot sizes
 * @param {number} width - Ad slot width
 * @returns {Array.<AdSize>}
 * filters slots sizes and removes sizes where width is greater than the screen width
 */
const filterMatchingSizes = (sizes, width) => sizes
  .map((size) => {
    if (isEmptyArray(size)) {
      return null;
    }

    if (!isSize(size)) {
      return filterMatchingSizes(size, width);
    }

    if (sizeMatches(size, width)) {
      return size;
    }

    return null;
  })
  .filter((size) => size !== null && !isEmptyArray(size));

/**
 * @param {Array} slots - A collection of ad slots
 * @param {number} width - Ad slot width
 * @returns {Array} filters slots sizes and removes sizes
 *      where width is greater than the screen width
 */
export const filterSlotsSizes = (slots, width) => slots
  .map((slot) => (!isEmptyArray(filterMatchingSizes(slot.sizes, width))
    ? {
      ...slot,
      sizes: filterMatchingSizes(slot.sizes, width),
    }
    : null))
  .filter((slot) => slot !== null);

/**
 * @param {AdSizes} sizes - A collection of ad slot sizes
 * @returns {number}
 * biggest height value from the sizes array to be reserved or 0 if there is not one
 */
export const getHeightToReserve = (sizes) => {
  if (Array.isArray(sizes)) {
    return Math.max(
      ...sizes.map((size) => {
        if (!isFluid(size) && isSizeTuple(size)) {
          return size[1];
        }

        return getHeightToReserve(size);
      }),
    );
  }

  return 0;
};
