/**
 * Throttle a function to only run every n milliseconds.
 *
 * Supports leading and trailing invocation.
 */
export const throttle = (func, wait = 100) => {
  let ctx;
  let throttleArgs;
  let rtn;
  let timer;
  let last = 0;

  const call = () => {
    timer = 0;
    last = +new Date();
    rtn = func.apply(ctx, throttleArgs);
    ctx = null;
    throttleArgs = null;
  };

  return function run(...args) {
    ctx = this;
    throttleArgs = args;
    const delta = new Date() - last;

    if (!timer) {
      if (delta >= wait) {
        call();
      } else {
        timer = setTimeout(call, wait - delta);
      }
    }

    return rtn;
  };
};
