import { throttle } from '../throttle.js';
import {
  createBlockingElementIndicators,
  injectAdSlotMarkers,
  appendGlobalSettingsIndicator,
  injectLimitMarker,
} from './overlays.js';
import * as selectors from './selectors.js';
import { THROTTLE_DELAY, LONG_FORM_MPU2_TO_MPU5_LIMIT } from './constants.js';
import { getElementLength, breakLoop, continueLoop } from './utils.js';

import '../typedef.js';

/**
 * Calls injectLimitMarker times numberOfMarkersInElement
 * @param {number} numberOfMarkersInElement
 *  - Total number of limit markers reached in single element
 * @param {Element} element - Current text element
 * @param {number} currentCharLimit - Current character count limit
 * @param {number} charsLeft - Number of characters left in text element
 */
export const injectLimitMarkers = (
  numberOfMarkersInElement, element, currentCharLimit, charsLeft,
) => {
  for (let i = 0; i < numberOfMarkersInElement; i += 1) {
    injectLimitMarker(element, currentCharLimit + ((currentCharLimit * i) - charsLeft));
  }
};

/**
 * Calculates number of limit markers that fit into one text element
 * @param {number} elementLength - Total number of characters in text element
 * @param {number} charsLeft - Number of characters left in text element
 * @param {number} currentCharLimit - Current character count limit
 * @returns {number} Number of character limit markers that fit in a single text element
 */
export const getNumberOfMarkers = (elementLength, charsLeft, currentCharLimit) => Math
  .floor((elementLength + charsLeft) / currentCharLimit);

/**
 * Get the array of objects with tag configuration
 * @param {Array.<Element>} textElements
 * @param {number} adsNumber - Maximum number of ads in a post
 * @param {number} mpu3To5 - Character count limit for MPU's 3 to 5
 * @param {number} mpu6Plus - Character count limit for MPU's 6 plus
 */
export const injectLongFormLimitMarkers = (textElements, adsNumber, mpu3To5, mpu6Plus) => {
  let currentCharLimit = mpu3To5;
  // charsLeft stores a number of characters left in paragraph after the limit marker
  let charsLeft = 0;
  let currentAd = 0;

  [...textElements].every((element) => {
    // get current element length
    const elementLength = getElementLength(element);
    // calculate char limit in current element by substracting chars left
    // from previous element from current char limit
    const charLimitInCurrentElement = currentCharLimit - charsLeft;

    if (elementLength < charLimitInCurrentElement) {
      charsLeft += elementLength;

      return continueLoop();
    }
    if (elementLength >= charLimitInCurrentElement) {
      const numberOfMarkersInElement = getNumberOfMarkers(
        elementLength, charsLeft, currentCharLimit,
      );

      injectLimitMarkers(numberOfMarkersInElement, element, currentCharLimit, charsLeft);

      currentAd += 1;
      // finish injecting limit markers if current ad reaches ads number
      if (currentAd === adsNumber) return breakLoop();
      // update chars left after injecting limit markers
      charsLeft = elementLength - ((numberOfMarkersInElement * currentCharLimit) - charsLeft);
      // if 4 limit markers were injected change the current char limit to mopu6plus
      if (currentAd === LONG_FORM_MPU2_TO_MPU5_LIMIT) currentCharLimit = mpu6Plus;
    }
    return continueLoop();
  });
};

/**
 * Creates limit tags for long form-type content
 * @param {Object} config - Configuration for long form overlay
 * @param {Object} config.charLimits - Character count limits for long form
 * @param {number} config.charLimits.mpu3To5 - Character count limit for MPU's 3 to 5
 * @param {number} config.charLimits.mpu6Plus - Character count limit for MPU's 6 plus
 * @param {number} config.adsNumber - Maximum number of ads in a long form
 */
export const getLongFormDebugOverlay = ({ charLimits, adsNumber }) => {
  appendGlobalSettingsIndicator([
    { label: 'MPU 3-5 char limit', value: charLimits.mpu3To5 },
    { label: 'MPU 6+ char limit', value: charLimits.mpu6Plus },
    { label: 'Ads number limit', value: adsNumber },
  ]);

  const textElements = selectors.getTextElements('longForm');
  injectLongFormLimitMarkers(
    textElements, adsNumber, charLimits.mpu3To5, charLimits.mpu6Plus,
  );
  const limitMarkers = selectors.getLimitMarkers();
  const blockingElements = selectors.getBlockingElements(limitMarkers);
  const semiBlockingElements = selectors.getSemiBlockingElements(limitMarkers);

  injectAdSlotMarkers(textElements, blockingElements, semiBlockingElements, limitMarkers);
  createBlockingElementIndicators([...blockingElements, ...semiBlockingElements]);

  window.onresize = throttle(() => {
    createBlockingElementIndicators([...blockingElements, ...semiBlockingElements]);
  }, THROTTLE_DELAY);
};
